import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Visita } from "./visitas-solicitadas/visita.model";


@Injectable({ providedIn: "root" })
export class VisitasService {
    private visitas: Visita[] = [];
  
    constructor(private http: HttpClient, private router: Router) {}
    private visitasUpdated = new Subject<{ visitas: Visita[] }>();

    validarPropiedad(propiedad: string) {
        return this.http.get<{
            error: boolean;
        }>("http://199.192.16.143:3000/api/propiedad/validar/" + propiedad);
    }

    solicitarVisita(propiedad: string | Blob,fecha: string | Blob,hora: string | Blob,cliente: string | Blob){
        return this.http
        .post<{ error: boolean; mensaje: string;}>(
        "http://199.192.16.143:3000/api/visita/solicitar",
        {
            propiedad:propiedad,
            fecha : fecha,
            hora: hora,
            cliente: cliente
        }
        );
    }
    
    getVisitasSolicitadas() {
        this.http
          .get<{ error: boolean; visitas: any }>(
            "http://199.192.16.143:3000/api/visita/solicitadas"
          )
          .pipe(
            map(visitaData => {
                return {
                  visitas: visitaData.visitas.map(visita => {
                    return {
                        id: visita.id,
                        propiedad: visita.propiedad,
                        agente: visita.agente,
                        fecha: visita.fecha,
                        hora: visita.hora
                    };
                  })
                };
              })
          )
          .subscribe(transformedVisitaData => {
            this.visitas = transformedVisitaData.visitas;
            this.visitasUpdated.next({
                visitas: [...this.visitas]
            });
          });
    }
    
    getVisitasSolicitadasListener() {
        return this.visitasUpdated.asObservable();
    }

    cancelarVisita(id: string){
        return this.http
        .post<{ error: boolean; mensaje: string;}>(
        "http://199.192.16.143:3000/api/visita/cancelar",
        {
            id:id,
        }
        );
    }

    aceptarVisita(id: string){
        return this.http
        .post<{ error: boolean; mensaje: string;}>(
        "http://199.192.16.143:3000/api/visita/aceptar",
        {
            id:id,
        }
        );
    }

    getVisitasAprobadas() {
        this.http
          .get<{ error: boolean; visitas: any }>(
            "http://199.192.16.143:3000/api/visita/aprobadas"
          )
          .pipe(
            map(visitaData => {
                return {
                  visitas: visitaData.visitas.map(visita => {
                    return {
                        id: visita.id,
                        propiedad: visita.propiedad,
                        agente: visita.agente,
                        fecha: visita.fecha,
                        hora: visita.hora
                    };
                  })
                };
              })
          )
          .subscribe(transformedVisitaData => {
            this.visitas = transformedVisitaData.visitas;
            this.visitasUpdated.next({
                visitas: [...this.visitas]
            });
          });
    }
    
    getVisitasAprobadasListener() {
        return this.visitasUpdated.asObservable();
    }

    detallesPropiedadProgramada(id: string){
        return this.http
        .post<{ error: boolean; propiedad: number; agente: string; fecha: string; hora: string}>(
        "http://199.192.16.143:3000/api/visita/propiedad-programada",
        {
            id:id
        }
        );
    }

    registrarEvaluacion(id:number,comentarioCliente: string,oferta: number,montoOferta: string,calificacionCliente: number,comentarioAgente: string,calificacionAgente: number){
        return this.http
        .post<{ error: boolean }>(
            "http://199.192.16.143:3000/api/visita/registro/programada",
        {
            id:id,
            comentarioCliente:comentarioCliente,
            oferta:oferta,
            montoOferta:montoOferta,
            calificacionCliente:calificacionCliente,
            comentarioAgente:comentarioAgente,
            calificacionAgente:calificacionAgente
        }
        );
    }

    getHistorialVisitas(paginaActual: number, visitasPorPagina: number){
        return this.http.get<{
            visitas:any;
            contador:number;
        }>("http://199.192.16.143:3000/api/visitas/historial/" + paginaActual + '/' + visitasPorPagina);   
    }
       
    getHistorialVisitasFiltro(paginaActual: number, visitasPorPagina: number, nombre:string){
        return this.http.get<{
            visitas:any;
            contador:number;
        }>("http://199.192.16.143:3000/api/visitas/historial/" + nombre + '/' + paginaActual + '/' + visitasPorPagina);   
    }
	
	getHistorialVisitasPorPropiedad(paginaActual: number, visitasPorPagina: number, codigo:string){
        return this.http.get<{
            visitas:any;
            contador:number;
        }>("http://199.192.16.143:3000/api/visitas/historial/propiedad/" + codigo + '/' + paginaActual + '/' + visitasPorPagina);   
    }

    detalleVisita(id: string){
        return this.http
        .post<{ error: boolean; propiedad: number; agente: string; fecha: string; hora: string ;comentarioCliente: string; oferta: boolean; monto: string; calificacionCliente: number; comentarioAgente: string; calificacionAgente: number}>(
        "http://199.192.16.143:3000/api/visita/historial/detalle",
        {
            id:id
        });
    } 
    registrarEvaluacionInstantanea(idCliente:number,comentarioCliente: string,oferta: number,montoOferta: string,calificacionCliente: number,propiedad: string,fecha: string,hora: string,representado: string,representadoNombre: string,representadoEmail: string,representadoCelular: string,representadoTelefono: string){
		console.log('Mostrar respresentado: ');
		console.log(representado);
        return this.http
        .post<{ error: boolean }>(
            "http://199.192.16.143:3000/api/visita/registro/instantanea",
        {
            idCliente:idCliente,
            comentarioCliente:comentarioCliente,
            oferta:oferta,
            montoOferta:montoOferta,
            calificacionCliente:calificacionCliente,
            propiedad: propiedad,
            fecha: fecha,
            hora: hora,
			representado: representado,
			representadoNombre: representadoNombre,
			representadoEmail: representadoEmail,
			representadoCelular: representadoCelular,
			representadoTelefono: representadoTelefono,
        }
        );
    }
}
