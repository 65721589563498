import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { GerenteService } from "../gerente.service";

@Component({
  selector: "app-agregar-sucursal",
  templateUrl: "./agregar-sucursal.component.html",
  styleUrls: ["./agregar-sucursal.component.css"]
})
export class AgregarSucursalComponent implements OnInit, OnDestroy {
  isLoading = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;

  
  private authStatusSub: Subscription;

  constructor(
    private authService: AuthService,
    public gerenteService: GerenteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        direccion: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        ciudad: new FormControl(null, { validators: [Validators.required] })
      });
      this.isLoading = false;
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }
  
  onSaveSucursal() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
      this.gerenteService.addSucursal(
        this.form.value.nombre,
        this.form.value.direccion,
        this.form.value.ciudad,
      );
    this.form.reset();
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}

