import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";

import { ReportesService } from "../reportes.service";
import { Visita } from "./visita.model";
import { Agente } from "../agente.model";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-visitas-personalizada",
  templateUrl: "./visitas-personalizada.component.html",
  styleUrls: ["./visitas-personalizada.component.css"]
})
export class VisitasPersonalizadaComponent implements OnInit {
  visitas: Visita[] = [];
  agentes: Agente[] = [];
  isLoading = false;
  codigoPropiedad = "0";
  fechaInicio = "0";
  fechaFin = "0";
  totalVisitas = 0;
  visitasPerPage = 10;
  currentPage = 1;
  form: FormGroup;
  pageSizeOptions = [10, 15, 20, 25];
  userIsAuthenticated = false;
  userId: string;
  private authStatusSub: Subscription;

  constructor(
    public reportesService: ReportesService,
    private authService: AuthService
  ) {}

  ngOnInit() {
	this.isLoading = true;
    this.userId = this.authService.getUserId();
      
    this.form = new FormGroup({
        codigo: new FormControl(null, {validators: [Validators.required]}),
		fechaInicio: new FormControl(null,),
		fechaFin: new FormControl(null,)
      });

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
	  
	  this.reportesService.getAgentes().subscribe(agentesData => {
        this.agentes = agentesData.agentes;
        this.isLoading = false;
      });
  }

  onChangedPage(pageData: PageEvent) {
    this.isLoading = true;  
    this.currentPage = pageData.pageIndex + 1;
    this.visitasPerPage = pageData.pageSize;
    if(this.form.value.codigo == null || this.form.value.fechaInicio == null || this.form.value.fechaFin == null){
			this.isLoading = false;
    }else{
        this.reportesService.getHistorialVisitasPersonalizado( this.currentPage -1, this.visitasPerPage,this.form.value.codigo,this.form.value.fechaInicio,this.form.value.fechaFin).subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.totalVisitas = visitasData.contador;
            this.isLoading = false;
        });
    }
  }

  onEditBusqueda() {
    this.isLoading = true;  
    this.currentPage = 1;
	this.codigoPropiedad = this.form.value.codigo;
    if(this.form.value.codigo == null || this.form.value.fechaInicio == null || this.form.value.fechaFin == null){
		this.isLoading = false;
    }else{
        this.reportesService.getHistorialVisitasPersonalizado( this.currentPage -1, this.visitasPerPage,this.form.value.codigo,this.form.value.fechaInicio,this.form.value.fechaFin).subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.totalVisitas = visitasData.contador;
            this.isLoading = false;
        });
    }
  }
}

