import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";
import { ActivatedRoute, ParamMap } from "@angular/router";

import { ClienteService } from "../cliente.service";
import { Requerimiento } from "../requerimiento.model";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-lista-requerimientos",
  templateUrl: "./lista-requerimientos.component.html",
  styleUrls: ["./lista-requerimientos.component.scss"]
})
export class ListaRequerimientosComponent implements OnInit {
  requerimientos: Requerimiento[] = [];
  isLoading = false;
  totalClientes = 0;
  clientesPerPage = 10;
  currentPage = 1;
  clienteId: string;
  form: FormGroup;
  pageSizeOptions = [10, 15, 20, 25];
  userIsAuthenticated = false;
  userId: string;
  private authStatusSub: Subscription;

  constructor(
    public clientesService: ClienteService,
    private authService: AuthService,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {


    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("clienteId")) {
            console.log(paramMap);
          this.clienteId = paramMap.get("clienteId");
          console.log(this.clienteId);
          this.isLoading = true;
          this.clientesService.getListaRequerimientos( this.currentPage -1, this.clientesPerPage, this.clienteId).subscribe(requerimientosData => {
              console.log(requerimientosData);
              this.requerimientos = requerimientosData.requerimientos;
              console.log(this.requerimientos);
              this.totalClientes = requerimientosData.contador;
              this.clientesService.getClienteVisto(this.clienteId).subscribe(vistoData => {
                });
              this.isLoading = false;
          });
        } else {
          this.clienteId = null;
        }
      });



    this.userId = this.authService.getUserId();
      
    this.form = new FormGroup({
        nombre: new FormControl(null,),
      });

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  onChangedPage(pageData: PageEvent) {
    this.isLoading = true;  
    this.currentPage = pageData.pageIndex + 1;
    this.clientesPerPage = pageData.pageSize;
    this.clientesService.getListaRequerimientos( this.currentPage -1, this.clientesPerPage, this.clienteId).subscribe(requerimientosData => {
        this.requerimientos = requerimientosData.requerimientos;
        this.totalClientes = requerimientosData.contador;
        this.isLoading = false;
    });
  }
}

