import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { GerenteService } from "../gerente.service"
import { AuthService } from "../../auth/auth.service";


@Component({
  selector: "app-agregar-usuario",
  templateUrl: "./agregar-usuario.component.html",
  styleUrls: ["./agregar-usuario.component.css"]
})
export class AgregarUsuarioComponent implements OnInit, OnDestroy {
  isLoading = false;
  isError =false;
  userIsAuthenticated = false;
  userId: string;
  sucursalId: string;
  form: FormGroup;
  private authStatusSub: Subscription;

  constructor(
    private authService: AuthService,
    public gerenteService: GerenteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellido: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        email: new FormControl(null, { validators: [Validators.required] }),
        password: new FormControl(null, { validators: [Validators.required] }),
        celular: new FormControl(null, { validators: [Validators.required] }),
        permisos: new FormControl(null, { validators: [Validators.required] }),
      });

      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("sucursalId")) {
            this.sucursalId = paramMap.get("sucursalId");
            this.isLoading = false;
        }else{
            this.sucursalId = null;
            this.isLoading = false;
            this.isError = true;
        }
      });
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
        this.isLoading = false;
      });
  }
  
  onSaveUsuario() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
      this.gerenteService.addUsuario(
        this.form.value.nombre,
        this.form.value.apellido,
        this.form.value.email,
        this.form.value.password,
        this.sucursalId,
        this.form.value.celular,
        this.form.value.permisos,
      );
    this.form.reset();
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
