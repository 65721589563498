import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { VisitasService } from "../visitas.service";
import { Visita } from "./visita.model";

@Component({
  selector: "app-visitas-solicitadas",
  templateUrl: "./visitas-solicitadas.component.html",
  styleUrls: ["./visitas-solicitadas.component.css"]
})
export class VisitasSolicitadasComponent implements OnInit, OnDestroy {
  isLoading = false;
  isPropiedadValida = false;
  isPropiedaBusqueda = false;
  isLoadingPropiedad = false;
  empresaValida = false;
  idCliente = '0';
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;
  visitas: Visita[] = [];

  private visitasSub: Subscription;
  private authStatusSub: Subscription;


  constructor(
    private authService: AuthService,
    public visitasService: VisitasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;

    this.visitasService.getVisitasSolicitadas();
    this.visitasSub = this.visitasService
      .getVisitasSolicitadasListener()
      .subscribe((visitaData: { visitas: Visita[]}) => {
        this.visitas = visitaData.visitas;
        this.isLoading = false;
      });
    
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
      
  }

  onCancelar(id: string){
    this.isLoading = true;
    this.visitasService.cancelarVisita(id).subscribe(visita => {
        this.visitasService.getVisitasSolicitadas();
        this.visitasSub = this.visitasService
          .getVisitasSolicitadasListener()
          .subscribe((visitaData: { visitas: Visita[]}) => {
            alert('Solicitud Cancelada');
            this.visitas = visitaData.visitas;
            this.isLoading = false;
          });
    },
    error => {
        alert('Error al cancelar');
        this.isLoading = false;  
    });
  }

  onAceptar(id: string){
    this.isLoading = true;
    this.visitasService.aceptarVisita(id).subscribe(visita => {
        this.visitasService.getVisitasSolicitadas();
        this.visitasSub = this.visitasService
          .getVisitasSolicitadasListener()
          .subscribe((visitaData: { visitas: Visita[]}) => {
            alert('Solicitud Aceptada');
            this.visitas = visitaData.visitas;
            this.isLoading = false;
          });
    },
    error => {
        alert('Error al Aceptar');
        this.isLoading = false;  
    });
  }
  ngOnDestroy() {
    this.visitasSub.unsubscribe();
  }
  
}
