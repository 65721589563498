import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { Usuario } from "./usuario.model";
import { Empresa } from "../registro-usuario/empresa.model";
import { Sucursal } from "../sucursal.model";
import { AuthService } from "../../auth/auth.service";
import { EmpresasService } from "../empresas.service";

@Component({
  selector: "app-lista-usuarios",
  templateUrl: "./lista-usuarios.component.html",
  styleUrls: ["./lista-usuarios.component.css"]
})
export class ListaUsuariosComponent implements OnInit, OnDestroy {
  isLoading = false;
  empresaValida: boolean;
  sucursalValida: boolean;
  isLoadingSurcursales = false;
  isLoadingUsuarios = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;



  empresas: Empresa[] = [];
  sucursales: Sucursal[] = [];
  usuarios: Usuario[] = [];
    private empresaId: string;
    private sucursalId: string;
  private authStatusSub: Subscription;
  private empresasSub: Subscription;
  private sucursalesSub: Subscription;
  private usuariosSub: Subscription;

  constructor(
    private authService: AuthService,
    public empresasService: EmpresasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.empresaValida=false;
    this.sucursalValida=false;
    this.form = new FormGroup({
        empresa: new FormControl(null, { validators: [Validators.required] }),
        sucursal: new FormControl(null, { validators: [Validators.required] }),
      });

    this.empresasService.getEmpresas(1,1);
    this.empresasSub = this.empresasService
      .getEmpresaUpdateListener()
      .subscribe((empresaData: { empresas: Empresa[]; empresaCount: number }) => {
        this.empresas = empresaData.empresas;
        this.isLoading = false;
      });

    
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
        
      });
  }
  
  
  
  onChangeEmpresas(deviceValue){
    this.isLoadingSurcursales = true;
    this.sucursalValida=false;
    this.empresaValida = false;
    this.empresasService.getSucursales(deviceValue);
    this.sucursalesSub = this.empresasService
      .getSucursalUpdateListener()
      .subscribe((sucursalData: { sucursales: Sucursal[] }) => {
        this.sucursales = sucursalData.sucursales;
        this.isLoadingSurcursales = false;
        this.empresaValida = true;
      });
  }

  onChangeSucursal(deviceValue){
      console.log(deviceValue);
      this.sucursalValida=false;
    this.isLoadingUsuarios = true;
    this.empresasService.getUsuarios(deviceValue);
    this.usuariosSub = this.empresasService
      .getUsuarioUpdateListener()
      .subscribe((usuarioData: { usuarios: Usuario[] }) => {
        this.usuarios = usuarioData.usuarios;
        this.isLoadingUsuarios = false;
        this.sucursalValida = true;
      });
      
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
