import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { Zona } from "../zona.model";
import { Agente } from "../agente.model";
import { AuthService } from "../../auth/auth.service";
import { ClienteService } from "../cliente.service";

@Component({
  selector: "app-asignar-cliente-codigo",
  templateUrl: "./asignar-cliente-codigo.component.html",
  styleUrls: ["./asignar-cliente-codigo.component.css"]
})
export class AsignarClienteCodigoComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingZonas = false;
  isCiudadValida = false;
  empresaValida = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;
  isPropiedadValida = false;
  isPropiedaBusqueda = false;
  isLoadingPropiedad = false;
   numeroDuplicado = false;
   cargandoNumero = false;
   agenteDuplicado = '';

  zonas: Zona[] = [];
  agentes: Agente[] = [];
  private authStatusSub: Subscription;
  private zonasSub: Subscription;

  constructor(
    private authService: AuthService,
    public clientesService: ClienteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoP: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoM: new FormControl(null, {}),
        celular: new FormControl(null, { validators: [Validators.required] }),
        correo: new FormControl(null, {}),
        fuente: new FormControl(1, { validators: [Validators.required] }),
        agente: new FormControl(null, {validators: [Validators.required]}),
        descripcion: new FormControl(null, { validators: [Validators.required,Validators.minLength(3)] }),
        propiedad: new FormControl(null, {validators: [Validators.required]}),
      });
      this.clientesService.getAgentes().subscribe(agentesData => {
        this.agentes = agentesData.agentes;
        this.isLoading = false;
      });
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });  
  }

  
  onSaveCliente() {
    if (this.form.invalid) {
      return;
    }
    var correo = '';
    if(this.form.value.celular != null){
        correo = this.form.value.celular;
    }
    if(this.isPropiedadValida){
        this.clientesService.asignarClienteCodigo(
            this.form.value.nombre,
            this.form.value.apellidoP,
            this.form.value.apellidoM,
            this.form.value.celular,
            correo,
            this.form.value.agente,
            this.form.value.descripcion,
            this.form.value.fuente,
            this.form.value.propiedad
        ).subscribe(clienteData => {
            this.router.navigate(["/admin/clientes"]);
        });
        this.form.reset();
    }
  }
  

  onChangeNumero(deviceValue){
   this.cargandoNumero = true;
   this.numeroDuplicado = false;
   if(deviceValue.length>7){
       this.clientesService.getNombreAgente(deviceValue).subscribe(nombreData => {
            if(nombreData.nombre!=""){
                this.agenteDuplicado = "Numero ya registrado, contactar a: "+nombreData.nombre;
                this.numeroDuplicado = true;
            }else{
                this.agenteDuplicado = "";
            }
            this.cargandoNumero = false;
        });    
       }else{
           this.cargandoNumero = false;    
       }
   }

  onValidarPropiedad(deviceValue){
    if(parseInt(deviceValue,10)>999){
      this.isLoadingPropiedad = true;
      this.isPropiedadValida = false;
      this.isPropiedaBusqueda = false;
      this.clientesService.validarPropiedad(deviceValue).subscribe(propiedad => {
          console.log(propiedad.error);
          this.isPropiedaBusqueda = true;
          if(propiedad.error == true){
              console.log('no encontrada');
              this.isPropiedadValida = false;  
          }else if(propiedad.error == false){
              console.log('encontrada');
              this.isPropiedadValida = true;
          }
          console.log(this.isPropiedadValida);
          this.isLoadingPropiedad = false;
      });
    }
    }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}

