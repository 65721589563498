import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";


import { Empresa } from "../registro-usuario/empresa.model";
import { Sucursal } from "../sucursal.model";
import { AuthService } from "../../auth/auth.service";
import { EmpresasService } from "../empresas.service";

@Component({
  selector: "app-registro-sucursal",
  templateUrl: "./registro-sucursal.component.html",
  styleUrls: ["./registro-sucursal.component.css"]
})
export class RegistroEmpresaSucursalComponent implements OnInit, OnDestroy {
  isLoading = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;
  totalEmpresas: number;

  empresas: Empresa[] = [];
  sucursales: Sucursal[] = [];
    private mode = "create";
    private empresaId: string;
  private authStatusSub: Subscription;
  private empresasSub: Subscription;
  private sucursalesSub: Subscription;

  constructor(
    private authService: AuthService,
    public empresasService: EmpresasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        direccion: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        ciudad: new FormControl(null, { validators: [Validators.required] }),
        empresa: new FormControl(null, { validators: [Validators.required] }),
      });

    this.empresasService.getEmpresas(1,1);
    this.empresasSub = this.empresasService
      .getEmpresaUpdateListener()
      .subscribe((empresaData: { empresas: Empresa[]; empresaCount: number }) => {
        this.empresas = empresaData.empresas;
        this.totalEmpresas = empresaData.empresaCount[0]['count'];
        this.isLoading = false;
      });

    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
        
      });
  }
  
  onSaveSucursal() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
      this.empresasService.addSucursal(
        this.form.value.nombre,
        this.form.value.direccion,
        this.form.value.empresa,
        this.form.value.ciudad,
      );
    this.form.reset();
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}

