import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { Usuario } from "./usuario.model";
import { Empresa } from "./empresa.model";
import { Sucursal } from "../sucursal.model";
import { AuthService } from "../../auth/auth.service";
import { EmpresasService } from "../empresas.service";

@Component({
  selector: "app-registro-usuario",
  templateUrl: "./registro-usuario.component.html",
  styleUrls: ["./registro-usuario.component.css"]
})
export class RegistroEmpresaUsuarioComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingSurcursales = false;
  empresaValida = false;
  userIsAuthenticated = false;
  userId: string;
  totalEmpresas = 0;
  form: FormGroup;


  empresas: Empresa[] = [];
  sucursales: Sucursal[] = [];
    private mode = "create";
    private empresaId: string;
  private authStatusSub: Subscription;
  private empresasSub: Subscription;
  private sucursalesSub: Subscription;

  constructor(
    private authService: AuthService,
    public empresasService: EmpresasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellido: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        email: new FormControl(null, { validators: [Validators.required] }),
        password: new FormControl(null, { validators: [Validators.required] }),
        codigoEmpresa: new FormControl(null, { validators: [Validators.required] }),
        celular: new FormControl(null, { validators: [Validators.required] }),
        sucursal: new FormControl(null, { validators: [Validators.required] }),
        permisos: new FormControl(null, { validators: [Validators.required] }),
      });

    this.empresasService.getEmpresas(1,1);
    this.empresasSub = this.empresasService
      .getEmpresaUpdateListener()
      .subscribe((empresaData: { empresas: Empresa[]; empresaCount: number }) => {
        this.empresas = empresaData.empresas;
        this.totalEmpresas = empresaData.empresaCount[0]['count'];
        this.isLoading = false;
      });

    
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
        
      });
  }
  
  onSaveUsuario() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    console.log('Primer Ingreso'+this.form.value.password);
      this.empresasService.addUsuario(
        this.form.value.nombre,
        this.form.value.apellido,
        this.form.value.email,
        this.form.value.password,
        this.form.value.sucursal,
        this.form.value.celular,
        this.form.value.permisos,
      );
    this.form.reset();
  }
  
  onChangeEmpresas(deviceValue){
    this.isLoadingSurcursales = true;
    this.empresaValida = false;
    this.empresasService.getSucursales(deviceValue);
    this.sucursalesSub = this.empresasService
      .getSucursalUpdateListener()
      .subscribe((sucursalData: { sucursales: Sucursal[] }) => {
        this.empresaValida = true;
        this.sucursales = sucursalData.sucursales;
        this.isLoadingSurcursales = false;
      });
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
