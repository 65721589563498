import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

import { Empresa } from "./lista-empresas/lista-empresas.model";
import { Sucursal } from "./sucursal.model";
import { Usuario } from "./lista-usuarios/usuario.model";


@Injectable({ providedIn: "root" })
export class EmpresasService {
  private empresas: Empresa[] = [];
  private sucursales: Sucursal[] = [];
  private usuarios: Usuario[] = [];
  
  private empresasUpdated = new Subject<{ empresas: Empresa[]; empresaCount: number }>();
  private sucursalesUpdated = new Subject<{ sucursales: Sucursal[] }>();
  private usuariosUpdated = new Subject<{ usuarios: Usuario[] }>();
  
  constructor(private http: HttpClient, private router: Router) {}

  getEmpresas(empresasPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${empresasPerPage}&page=${currentPage}`;
    this.http
      .get<{ elError: boolean; empresas: any; contador: number }>(
        "http://68.183.168.180:3000/api/empresas/lista"
      )
      .pipe(
        map(empresaData => {
            console.log('Ingresamos al get');
            console.log(empresaData);
          return {
            empresas: empresaData.empresas.map(empresa => {
              return {
                id: empresa.id,
                nombre: empresa.nombre,
                nit: empresa.nit
              };
            })
            ,maxEmpresas: empresaData.contador
          };
        })
      )
      .subscribe(transformedEmpresaData => {
        this.empresas = transformedEmpresaData.empresas;
        this.empresasUpdated.next({
          empresas: [...this.empresas],
          empresaCount: transformedEmpresaData.maxEmpresas
        });
      });
  }

  getEmpresaUpdateListener() {
    return this.empresasUpdated.asObservable();
  }

  getPost(id: string) {
    return this.http.get<{
      _id: string;
      title: string;
      content: string;
      imagePath: string;
      creator: string;
    }>("http://localhost:3000/api/posts/" + id);
  }

  addEmpresa(nombre: string | Blob, nit: string | Blob, direccion: string| Blob, tipofactura: string| Blob, tipoempresa: string| Blob, ciudad: string| Blob, image: File) {
      console.log('addEmpresa');
    const empresaData = new FormData();
    
    empresaData.append("nombre", nombre);
    empresaData.append("nit", nit);
    empresaData.append("direccion", direccion);
    empresaData.append("tipofactura", tipofactura);
    empresaData.append("tipoempresa", tipoempresa);
    empresaData.append("ciudad", ciudad);
    empresaData.append("image", image, "logo");
    this.http
      .post<{ nombre: String; nit: number; direccion: String; tipofactura: String; tipoempresa: String; foto: String }>(
        "http://68.183.168.180:3000/api/empresas/registro",
        empresaData
      )
      .subscribe(responseData => {
        this.router.navigate(["/empresas"]);
      });
  }


getSucursales(empresa: string) {
    this.http
      .get<{ elError: boolean; sucursales: any  }>(
        "http://68.183.168.180:3000/api/empresas/sucursales/" + empresa
      )
      .pipe(
        map(sucursalData => {
          return {
            sucursales: sucursalData.sucursales.map(sucursal => {
              return {
                id: sucursal.id,
                nombre: sucursal.nombre,
              };
            })
          };
        })
      )
      .subscribe(transformedSucursalData => {
        this.sucursales = transformedSucursalData.sucursales;
        this.sucursalesUpdated.next({
          sucursales: [...this.sucursales]
        });
      });
  }

  getSucursalUpdateListener() {
    return this.sucursalesUpdated.asObservable();
  }

  getUsuarios(sucursal: string) {
    this.http
      .get<{ elError: boolean; usuarios: any  }>(
        "http://68.183.168.180:3000/api/usuario/lista/" + sucursal
      )
      .pipe(
        map(usuarioData => {
          return {
            usuarios: usuarioData.usuarios.map(usuario => {
              return {
                nombre: usuario.nombre,
                apellido: usuario.apellido,
                email: usuario.email,
                celular: usuario.celular
              };
            })
          };
        })
      )
      .subscribe(transformedUsuarioData => {
        this.usuarios = transformedUsuarioData.usuarios;
        this.usuariosUpdated.next({
          usuarios: [...this.usuarios]
        });
      });
  }

  getUsuarioUpdateListener() {
    return this.usuariosUpdated.asObservable();
  }

  addUsuario(nombre: string | Blob, apellido: string | Blob, email: string| Blob, password: string| Blob, sucursal: string| Blob, celular: string| Blob, permisos: string| Blob) {
  const usuarioData = new FormData();
  
  console.log(nombre);
  console.log(apellido);
  console.log(email);
  console.log(password);
  console.log(celular);
  console.log(sucursal);
  console.log(permisos);

  usuarioData.append("nombre", nombre);
  usuarioData.append("apellido", apellido);
  usuarioData.append("email", email);
  usuarioData.append("password", password);
  usuarioData.append("celular", celular);
  usuarioData.append("sucursal", sucursal);
  usuarioData.append("permisos", permisos);

  console.log(usuarioData);
  
  this.http
    .post<{ elError: string; mensaje: string}>(
      "http://68.183.168.180:3000/api/usuario/registro",
      {
        "nombre": nombre,
        "apellido": apellido,
        "email": email,
        "password": password,
        "celular": celular,
        "sucursal": sucursal,
        "permisos": permisos
        }
    )
    .subscribe(responseData => {
      this.router.navigate(["/empresas/lista_usuarios"]);
    });
}

addSucursal(nombre: string | Blob, direccion: string | Blob, empresa: string| Blob, ciudad: string| Blob) {
    const sucursalData = new FormData();    
    this.http
      .post<{ elError: string; mensaje: string}>(
        "http://68.183.168.180:3000/api/empresa/sucursal/registro",
        {
            "nombre": nombre,
            "direccion": direccion,
            "empresa" : empresa,
            "ciudad" : ciudad
        }
      )
      .subscribe(responseData => {
        this.router.navigate(["/empresas/lista_usuarios"]);
      });
  }
/*
  updatePost(id: string, title: string, content: string, image: File | string) {
    let postData: Post | FormData;
    if (typeof image === "object") {
      postData = new FormData();
      postData.append("id", id);
      postData.append("title", title);
      postData.append("content", content);
      postData.append("image", image, title);
    } else {
      postData = {
        id: id,
        title: title,
        content: content,
        imagePath: image,
        creator: null
      };
    }
    this.http
      .put("http://localhost:3000/api/posts/" + id, postData)
      .subscribe(response => {
        this.router.navigate(["/"]);
      });
  }

  deletePost(postId: string) {
    return this.http.delete("http://localhost:3000/api/posts/" + postId);
  }
  */
}

