import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

/*
import { Zona } from "../zona.model";
import { AuthService } from "../../auth/auth.service";
import { ClienteService } from "../cliente.service";
*/

@Component({
  selector: "app-registro-propiedad-caracteristicas",
  templateUrl: "./registro-propiedad-caracteristicas.component.html",
  styleUrls: ["./registro-propiedad-caracteristicas.component.scss"]
})
export class RegistroPropiedadCaracteristicasComponent implements OnInit, OnDestroy {
    
  isLoading = false;
  /*
  isLoadingZonas = false;
  isCiudadValida = false;
  empresaValida = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;


  zonas: Zona[] = [];
  private authStatusSub: Subscription;
  private zonasSub: Subscription;

  constructor(
    private authService: AuthService,
    public clientesService: ClienteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  */
  ngOnInit() {
      /*
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoP: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoM: new FormControl(null, {}),
        celular: new FormControl(null, { validators: [Validators.required] }),
        descripcion: new FormControl(null, { validators: [Validators.required,Validators.minLength(3)] }),
        password: new FormControl(null, { validators: [Validators.required] }),
        estado: new FormControl(null, { validators: [Validators.required] }),
        tipo: new FormControl(null, { validators: [Validators.required] }),
        precioMax: new FormControl(null, { validators: [Validators.required] }),
        ciudad: new FormControl(null, { validators: [Validators.required] }),
        zona1: new FormControl(null, { validators: [Validators.required] }),
        zona2: new FormControl(null, { }),
        zona3: new FormControl(null, { }),
        zona4: new FormControl(null, { }),
        zona5: new FormControl(null, { }),
        zona6: new FormControl(null, { }),
        zona7: new FormControl(null, { }),
        zona8: new FormControl(null, { }),
        zona9: new FormControl(null, { }),
        zona10: new FormControl(null, { }),
      });
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
      */
  }

  /*
  onSaveCliente() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    console.log('Primer Ingreso'+this.form.value.password);
      this.empresasService.addUsuario(
        this.form.value.nombre,
        this.form.value.apellido,
        this.form.value.email,
        this.form.value.password,
        this.form.value.sucursal,
        this.form.value.celular,
        this.form.value.permisos,
      );
    this.form.reset();
  }
  */

  /*
  onChangeEmpresas(deviceValue){
    this.isLoadingZonas = true;
    this.isCiudadValida = false;
    this.clientesService.getZonas(deviceValue);
    this.zonasSub = this.clientesService
      .getZonaUpdateListener()
      .subscribe((zonaData: { zonas: Zona[] }) => {
        this.isCiudadValida = true;
        this.zonas = zonaData.zonas;
        this.isLoadingZonas = false;
      });
  }
*/
  ngOnDestroy() {
    //this.authStatusSub.unsubscribe();
  }
  
}
