import { Component, OnInit, OnDestroy } from "@angular/core";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";

import { Empresa } from "./lista-empresas.model";
import { EmpresasService } from "../empresas.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-lista-empresas",
  templateUrl: "./lista-empresas.component.html",
  styleUrls: ["./lista-empresas.component.css"]
})
export class ListaEmpresasComponent implements OnInit, OnDestroy {
  empresas: Empresa[] = [];
  isLoading = false;
  totalEmpresas = 0;
  empresasPerPage = 2;
  currentPage = 1;
  pageSizeOptions = [1, 2, 5, 10];
  userIsAuthenticated = false;
  userId: string;
  private empresasSub: Subscription;
  private authStatusSub: Subscription;
  displayedColumns: string[] = ['id', 'nombre','nit'];

  constructor(
    public empresasService: EmpresasService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.empresasService.getEmpresas(this.empresasPerPage, this.currentPage);
    this.userId = this.authService.getUserId();
    this.empresasSub = this.empresasService
      .getEmpresaUpdateListener()
      .subscribe((empresaData: { empresas: Empresa[]; empresaCount: number }) => {
      
        this.isLoading = false;
        this.totalEmpresas = empresaData.empresaCount[0]['count'];
        this.empresas = empresaData.empresas;
      
      });
      
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  onChangedPage(pageData: PageEvent) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.empresasPerPage = pageData.pageSize;
    this.empresasService.getEmpresas(this.empresasPerPage, this.currentPage);
  }

  ngOnDestroy() {
    this.empresasSub.unsubscribe();
    this.authStatusSub.unsubscribe();
  }

  
}
