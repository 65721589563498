import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { VisitasService } from "../visitas.service";

@Component({
  selector: "app-solicitar-visita",
  templateUrl: "./solicitar-visita.component.html",
  styleUrls: ["./solicitar-visita.component.css"]
})
export class SolicitarVisitaComponent implements OnInit {
  isLoading = false;
  isPropiedadValida = false;
  isPropiedaBusqueda = false;
  isLoadingPropiedad = false;
  empresaValida = false;
  idCliente = '0';
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;

  private authStatusSub: Subscription;


  constructor(
    private authService: AuthService,
    public visitasService: VisitasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        propiedad: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        fecha: new FormControl(null, { validators: [Validators.required] }),
        hora: new FormControl(null, { validators: [Validators.required] }),
      });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("clienteId")) {
            this.idCliente = paramMap.get('clienteId')  
            this.isLoading = false;
        }
    });
    
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
      
  }

  onValidarPropiedad(deviceValue){
      if(parseInt(deviceValue,10)>999){
        this.isLoadingPropiedad = true;
        this.isPropiedadValida = false;
        this.isPropiedaBusqueda = false;
        this.visitasService.validarPropiedad(deviceValue).subscribe(propiedad => {
            console.log(propiedad.error);
            this.isPropiedaBusqueda = true;
            if(propiedad.error == true){
                console.log('no encontrada');
                this.isPropiedadValida = false;  
            }else if(propiedad.error == false){
                console.log('encontrada');
                this.isPropiedadValida = true;
            }
            console.log(this.isPropiedadValida);
            this.isLoadingPropiedad = false;
        });
      }
  }

  onSolicitarVisita(){
    this.isLoading = true;  
    if (this.form.invalid) {
        this.isLoading = false;  
        return;
    }
    if(this.isPropiedadValida){
        this.visitasService.solicitarVisita(this.form.value.propiedad,this.form.value.fecha,this.form.value.hora,this.idCliente).subscribe(visita => {
            alert('Solicitud Enviada');
            this.isLoading = false;  
        },
        error => {
            alert('Error en la Solicitud');
            this.isLoading = false;  
        });
    }else{
        this.isLoading = false;  
        return;
    }
  }
  
}
