import { Component, OnInit} from "@angular/core";
import { PageEvent } from "@angular/material";
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Producto } from "../producto.model";
import { InventarioService } from "../inventario.service";

@Component({
  selector: "app-productos",
  templateUrl: "./productos.component.html",
  styleUrls: ["./productos.component.css"]
})
export class ProductosComponent implements OnInit {
  productos: Producto[] = [];
  contadorProductos: number;
  isLoading = false;
  buscadorActivado = false;
  totalProductos = 10;
  prouctosPerPage = 2;
  pageSizeOptions = [1, 2, 5, 10];
  breakpoint = 1;
  private productosSub: Subscription;
  form: FormGroup;

  constructor(public inventarioService: InventarioService) {}

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 3;
    this.isLoading = true;


    this.inventarioService.getProductosSimple(0,this.prouctosPerPage).subscribe(productoData => {
        console.log(productoData);
        this.productos = productoData.productos;
        this.contadorProductos = productoData.contador;
        console.log(this.contadorProductos);
        this.totalProductos = productoData.contador;
        this.isLoading = false;
      });
      console.log(this.contadorProductos);
      
      this.form = new FormGroup({
        keyword: new FormControl(null, { validators: [Validators.required] })
      });
  }

  onChangedPage(pageData: PageEvent) {
    this.prouctosPerPage = pageData.pageSize;
    this.isLoading = true;
    if(this.buscadorActivado == false){
        this.inventarioService.getProductosSimple(pageData.pageIndex,this.prouctosPerPage).subscribe(productoData => {
            this.productos = productoData.productos;
            this.contadorProductos = productoData.contador;
            this.totalProductos =productoData.contador;
            this.isLoading = false;
          });
    }else{
        this.inventarioService.getProductosBusquedaSimple(this.form.value.keyword,pageData.pageIndex,this.prouctosPerPage).subscribe(productoData => {
            this.productos = productoData.productos;
            this.contadorProductos = productoData.contador;
            this.totalProductos = productoData.contador;
            this.isLoading = false;
          });
    }
  }

  onEditBusqueda(){
    this.buscadorActivado = true;
    this.isLoading = true;
    this.inventarioService.getProductosBusquedaSimple(this.form.value.keyword,0,this.prouctosPerPage).subscribe(productoData => {
        this.productos = productoData.productos;
        this.contadorProductos = productoData.contador;
        this.totalProductos = productoData.contador;
        this.isLoading = false;
      });
  }

  onCleanBusqueda(){
    this.buscadorActivado = false;
    this.isLoading = true;
    this.form.reset({ keyword: '' });
    this.inventarioService.getProductosSimple(0,this.prouctosPerPage).subscribe(productoData => {
        this.productos = productoData.productos;
        this.contadorProductos = productoData.contador;
        this.totalProductos =productoData.contador;
        this.isLoading = false;
      });
  }
  /*
  onDelete(postId: string) {
    this.postsService.deletePost(postId);
  }
  */

 onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 3;
  }
  /*
  ngOnDestroy() {
    this.inventarioService.unsubscribe();
  }
  */
}

