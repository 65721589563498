import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";

import { VisitasService } from "../visitas.service";
import { Visita } from "./visita.model";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-historial-visitas",
  templateUrl: "./historial-visitas.component.html",
  styleUrls: ["./historial-visitas.component.css"]
})
export class HistorialVisitasComponent implements OnInit {
  visitas: Visita[] = [];
  isLoading = false;
  totalVisitas = 0;
  visitasPerPage = 10;
  currentPage = 1;
  form: FormGroup;
  pageSizeOptions = [10, 15, 20, 25];
  userIsAuthenticated = false;
  userId: string;
  private authStatusSub: Subscription;

  constructor(
    public visitasService: VisitasService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.visitasService.getHistorialVisitas( this.currentPage -1, this.visitasPerPage).subscribe(visitasData => {        
        this.visitas = visitasData.visitas;
        this.totalVisitas = visitasData.contador;
        this.isLoading = false;
    });
    this.userId = this.authService.getUserId();
      
    this.form = new FormGroup({
        nombre: new FormControl(null,),
      });

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  onChangedPage(pageData: PageEvent) {
    this.isLoading = true;  
    this.currentPage = pageData.pageIndex + 1;
    this.visitasPerPage = pageData.pageSize;
    if(this.form.value.nombre == null){
        this.visitasService.getHistorialVisitas( this.currentPage -1, this.visitasPerPage).subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.totalVisitas = visitasData.contador;
            this.isLoading = false;
        });
    }else{
        this.visitasService.getHistorialVisitasFiltro( this.currentPage -1, this.visitasPerPage,this.form.value.nombre).subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.totalVisitas = visitasData.contador;
            this.isLoading = false;
        });
    }
  }

  onEditBusqueda() {
    this.isLoading = true;  
    this.currentPage = 1;
    if(this.form.value.nombre == null){
        this.visitasService.getHistorialVisitas( this.currentPage -1, this.visitasPerPage).subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.totalVisitas = visitasData.contador;
            this.isLoading = false;
        });
    }else{
        this.visitasService.getHistorialVisitasFiltro( this.currentPage -1, this.visitasPerPage,this.form.value.nombre).subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.totalVisitas = visitasData.contador;
            this.isLoading = false;
        });
    }
  }
}

