import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PostListComponent } from './post/post-list/post-list.component';
import { PostCreateComponent } from './post/post-create/post-create.component';
import { LoginComponent } from './auth/login/login.component';
import { InicioComponent } from './inicio/inicio.component';
import { AuthGuard } from './auth/auth.guard';
import { ListaEmpresasComponent } from './empresas/lista-empresas/lista-empresas.component';
import { RegistroEmpresaComponent } from './empresas/registro-empresa/registro-empresa.component';
import { RegistroEmpresaUsuarioComponent } from './empresas/registro-usuario/registro-usuario.component';
import { ListaUsuariosComponent } from './empresas/lista-usuarios/lista-usuarios.component';
import { RegistroEmpresaSucursalComponent } from './empresas/registro-sucursal/registro-sucursal.component';
import { AgregarProductoComponent } from './inventario/agregar-producto/agregar-producto.component';
import { ProductosComponent } from './inventario/productos/productos.component';
import { EditarProductoComponent } from './inventario/editar-producto/editar-producto.component';
import { ModificarCantidadComponent } from './inventario/modificar-cantidad/modificar-cantidad.component';
import { ListaSucursalesComponent } from './gerente/lista-sucursales/lista-sucursales.component';
import { AgregarSucursalComponent } from './gerente/agregar-sucursal/agregar-sucursal.component';
import { AgregarUsuarioComponent } from './gerente/agregar-usuario/agregar-usuario.component';
import { ListaUsuariosSucursalComponent } from './gerente/lista-usuarios-sucursal/lista-usuarios-sucursal.component';
import { AgregarClienteComponent } from './admin-cliente/agregar-cliente/agregar-cliente.component';
import { PropiedadesRelacionadasComponent } from './admin-cliente/propiedades_relacionadas/propiedades_relacionadas.component';
import { ListaClientesComponent } from './admin-cliente/lista-clientes/lista-clientes.component';
import { ListaRequerimientosComponent } from './admin-cliente/lista-requerimientos/lista-requerimientos.component';
import { ModificarClienteComponent } from './admin-cliente/modificar-cliente/modificar-cliente.component';
import { AgregarRequerimientoComponent } from './admin-cliente/agregar-requerimiento/agregar-requerimiento.component';
import { AsignarClienteComponent } from './admin-cliente/asignar-cliente/asignar-cliente.component';
import { ListaClientesAdminComponent } from './admin-cliente/lista-clientes-admin/lista-clientes-admin.component';
import { SolicitarVisitaComponent } from './admin-visitas/solicitar-visita/solicitar-visita.component';
import { VisitasSolicitadasComponent } from './admin-visitas/visitas-solicitadas/visitas-solicitadas.component';
import { VisitasAprobadasComponent } from './admin-visitas/visitas-aprobadas/visitas-aprobadas.component';
import { RegistrarVisitaProgramadaComponent } from './admin-visitas/registrar-visita-programada/registrar-visita-programada.component';
import { HistorialVisitasComponent } from './admin-visitas/historial-visitas/historial-visitas.component';
import { DetalleVisitaComponent } from './admin-visitas/detalle-visita/detalle-visita.component';
import { RegistrarVisitaInstantaneaComponent } from './admin-visitas/registrar-visita-instantanea/registrar-visita-instantanea.component';
import { MisPropiedadesComponent } from './admin-propiedad/mis-propiedades/mis-propiedades.component';
import { AsignarClienteCodigoComponent } from './admin-cliente/asignar-cliente-codigo/asignar-cliente-codigo.component';
import { ModificarZonasComponent } from './admin-cliente/modificar-zonas/modificar-zonas.component';
import { PropiedadRequerimientoComponent } from './admin-propiedad/propiedad-requerimiento/propiedad-requerimiento.component';
import { VisitassAgentesAdminComponent } from './admin-reportes/visitas-agentes/visitas-agentes.component';
import { AporteFirmaAnualComponent } from './admin-reportes/aporte-firma-anual/aporte-firma-anual.component';
import { HistorialPorPropiedadComponent } from './admin-visitas/historial-por-propiedad/historial-por-propiedad.component';
import { VisitasAgentesAdmin30Component } from './admin-reportes/visitas-agentes-30/visitas-agentes-30.component';
import { VisitasPersonalizadaComponent } from './admin-reportes/visitas-personalizada/visitas-personalizada.component';

const routes: Routes = [
    { path: '', component: InicioComponent, canActivate:[AuthGuard]},
    { path: 'empresas', component: ListaEmpresasComponent, canActivate:[AuthGuard]},
    { path: 'empresas/registro', component: RegistroEmpresaComponent, canActivate:[AuthGuard]},
    { path: 'empresa/sucursal/registro', component: RegistroEmpresaSucursalComponent, canActivate:[AuthGuard] },
    { path: 'empresas/registro_usuario', component: RegistroEmpresaUsuarioComponent, canActivate:[AuthGuard]},
    { path: 'empresas/lista_usuarios', component: ListaUsuariosComponent, canActivate:[AuthGuard]},
    { path: 'productos/agregar_producto', component: AgregarProductoComponent, canActivate:[AuthGuard]},
    { path: 'productos/lista', component: ProductosComponent, canActivate:[AuthGuard]},
    { path: 'productos/editar/:productoId', component: EditarProductoComponent, canActivate:[AuthGuard]},
    { path: 'productos/editar-cantidad/:productoId', component: ModificarCantidadComponent, canActivate:[AuthGuard]},
    { path: 'gerente/lista_sucursales', component: ListaSucursalesComponent, canActivate:[AuthGuard]},
    { path: 'gerente/agregar-sucursal', component: AgregarSucursalComponent, canActivate:[AuthGuard]},
    { path: 'gerente/agregar-usuario/:sucursalId', component: AgregarUsuarioComponent, canActivate:[AuthGuard]},
    { path: 'gerente/usuarios/:sucursalId', component: ListaUsuariosSucursalComponent, canActivate:[AuthGuard]},
    { path: 'create', component: PostCreateComponent},
    { path: 'edit/:postId', component: PostCreateComponent},
    { path: 'login', component: LoginComponent },
    { path: 'agente/agregar_cliente', component: AgregarClienteComponent, canActivate:[AuthGuard] },
    { path: 'agente/propiedades_relacionadas/:idRequerimiento/:pagina', component: PropiedadesRelacionadasComponent, canActivate:[AuthGuard] },
    { path: 'agente/clientes', component: ListaClientesComponent, canActivate:[AuthGuard] },
    { path: 'agente/cliente/requerimientos/:clienteId', component: ListaRequerimientosComponent, canActivate:[AuthGuard] },
    { path: 'agente/cliente/modificar/:clienteId', component: ModificarClienteComponent, canActivate:[AuthGuard] },
    { path: 'agente/cliente/agregar/requerimiento/:clienteId', component: AgregarRequerimientoComponent, canActivate:[AuthGuard] },
    { path: 'admin/asignar/cliente', component: AsignarClienteComponent, canActivate:[AuthGuard] },
    { path: 'admin/clientes', component: ListaClientesAdminComponent, canActivate:[AuthGuard] },
    { path: 'visitas/solicitar/:clienteId', component: SolicitarVisitaComponent, canActivate:[AuthGuard] },
    { path: 'visitas/solicitadas', component: VisitasSolicitadasComponent, canActivate:[AuthGuard] },
    { path: 'visitas/aprobadas', component: VisitasAprobadasComponent, canActivate:[AuthGuard] },
    { path: 'visitas/registrar/programada/:visitaId', component: RegistrarVisitaProgramadaComponent, canActivate:[AuthGuard] },
    { path: 'visitas/historial', component: HistorialVisitasComponent, canActivate:[AuthGuard] },
    { path: 'visitas/detalle/:visitaId', component: DetalleVisitaComponent, canActivate:[AuthGuard] },
    { path: 'visitas/registrar/instanteane/:clienteId', component: RegistrarVisitaInstantaneaComponent, canActivate:[AuthGuard] },
    { path: 'propiedades/propias', component: MisPropiedadesComponent, canActivate:[AuthGuard] },
    { path: 'admin/asignar/cliente-codigo', component: AsignarClienteCodigoComponent, canActivate:[AuthGuard] },
    { path: 'agente/cliente/requerimiento/zonas/:requerimientoId', component: ModificarZonasComponent, canActivate:[AuthGuard] },
    { path: 'propiedades/requerimientos/:propiedadId', component: PropiedadRequerimientoComponent, canActivate:[AuthGuard] },
    { path: 'admin/reportes/visitas', component: VisitassAgentesAdminComponent, canActivate:[AuthGuard] },
	{ path: 'admin/reportes/firma/anual', component: AporteFirmaAnualComponent, canActivate:[AuthGuard] },
	{ path: 'visitas/historial/propiedad', component: HistorialPorPropiedadComponent, canActivate:[AuthGuard] },
	{ path: 'admin/reportes/visitas/30', component: VisitasAgentesAdmin30Component, canActivate:[AuthGuard] },
	{ path: 'admin/reportes/visitas/personalizada', component: VisitasPersonalizadaComponent, canActivate:[AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
