import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { VisitasService } from "../visitas.service";

@Component({
  selector: "app-registrar-visita-programada",
  templateUrl: "./registrar-visita-programada.component.html",
  styleUrls: ["./registrar-visita-programada.component.css"]
})
export class RegistrarVisitaProgramadaComponent implements OnInit {
  isLoading = false;
  isPropiedadValida = false;
  isPropiedaBusqueda = false;
  isLoadingPropiedad = false;
  empresaValida = false;
  idvisita = '0';
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;

  propiedad: number;
  agente: string;
  fecha: string;
  hora: string;

  private authStatusSub: Subscription;


  constructor(
    private authService: AuthService,
    public visitasService: VisitasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        comentarioCliente: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        oferta: new FormControl(null, { validators: [Validators.required] }),
        monto: new FormControl(null, { }),
        calificacionCliente: new FormControl(null, { validators: [Validators.required] }),
        comentarioAgente: new FormControl(null, { validators: [Validators.required] }),
        calificacionAgente: new FormControl(null, { validators: [Validators.required] }),
      });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("visitaId")) {
            this.idvisita = paramMap.get('visitaId');
            this.visitasService.detallesPropiedadProgramada(this.idvisita).subscribe(propiedadData => {
                this.propiedad = propiedadData.propiedad;
                this.agente = propiedadData.agente;
                this.fecha = propiedadData.fecha;
                this.hora = propiedadData.hora;
                this.isLoading = false;
            });
        }
    });
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  onRegistrarEvaluacion(){
    this.isLoading = true;  
    if (this.form.invalid) {
        this.isLoading = false;  
        return;
    }
    var montoOferta: any;
    if(this.form.value.oferta == 1)
    montoOferta = this.form.value.monto;
    else
    montoOferta = '0';
        this.visitasService.registrarEvaluacion(parseInt(this.idvisita,10),this.form.value.comentarioCliente,this.form.value.oferta,montoOferta,this.form.value.calificacionCliente,this.form.value.comentarioAgente,this.form.value.calificacionAgente).subscribe(visita => {
            alert('Visita Registrada');
            this.router.navigate(["/visitas/historial"]);
            this.isLoading = false;  
        },
        error => {
            alert('Error en la Solicitud');
            this.isLoading = false;  
        });
  }
  
}
