import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { Zona } from "../zona.model";
import { AuthService } from "../../auth/auth.service";
import { ClienteService } from "../cliente.service";

@Component({
  selector: "app-modificar-zonas",
  templateUrl: "./modificar-zonas.component.html",
  styleUrls: ["./modificar-zonas.component.css"]
})
export class ModificarZonasComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingZonas = false;
  isCiudadValida = false;
  empresaValida = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;
  zona2 : string;
  zona3 : string;
  zona4 : string;
  zona5 : string;
  zona6 : string;
  zona7 : string;
  zona8 : string;
  zona9 : string;
  zona10 : string;

  zonas: Zona[] = [];
  private authStatusSub: Subscription;
  private zonasSub: Subscription;

  constructor(
    private authService: AuthService,
    public clientesService: ClienteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        zona1: new FormControl(null, { validators: [Validators.required] }),
        zona2: new FormControl(null, { }),
        zona3: new FormControl(null, { }),
        zona4: new FormControl(null, { }),
        zona5: new FormControl(null, { }),
        zona6: new FormControl(null, { }),
        zona7: new FormControl(null, { }),
        zona8: new FormControl(null, { }),
        zona9: new FormControl(null, { }),
        zona10: new FormControl(null, { }),
      });
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        var temporalVistas
        if (paramMap.has("requerimientoId")) {
            this.clientesService.getZonasModificar(parseInt(paramMap.get('requerimientoId')));
            this.zonasSub = this.clientesService
            .getZonaModificarUpdateListener()
            .subscribe((zonaData: { zonas: Zona[] }) => {
                this.zonas = zonaData.zonas;
                this.clientesService.getZonasRequerimiento(parseInt(paramMap.get('requerimientoId'))).subscribe(zonasRequerimiento => {
                    this.form.controls['zona1'].setValue(zonasRequerimiento.zona1);
                    this.form.controls['zona2'].setValue(zonasRequerimiento.zona2);
                    this.form.controls['zona3'].setValue(zonasRequerimiento.zona3);
                    this.form.controls['zona4'].setValue(zonasRequerimiento.zona4);
                    this.form.controls['zona5'].setValue(zonasRequerimiento.zona5);
                    this.form.controls['zona6'].setValue(zonasRequerimiento.zona6);
                    this.form.controls['zona7'].setValue(zonasRequerimiento.zona7);
                    this.form.controls['zona8'].setValue(zonasRequerimiento.zona8);
                    this.form.controls['zona9'].setValue(zonasRequerimiento.zona9);
                    this.form.controls['zona10'].setValue(zonasRequerimiento.zona10);
                });
                this.isLoading = false;
            });
        }
    });
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
      
  }

  
  onModificarZonas() {
    if (this.form.invalid) {
      return;
    }

    
    var correo = '';
    this.isLoading = true;
       if(this.form.value.zona2 == 0 || this.form.value.zona2 == null){
           this.zona2 = '0';
       }else{
           this.zona2 = this.form.value.zona2;
       }
       if(this.form.value.zona3 == 0 || this.form.value.zona3 == null){
           this.zona3 = '0';
       }else{
           this.zona3 = this.form.value.zona3;
       }
       if(this.form.value.zona4 == 0 || this.form.value.zona4 == null){
           this.zona4 = '0';
       }else{
           this.zona4 = this.form.value.zona4;
       }
       if(this.form.value.zona5 == 0 || this.form.value.zona5 == null){
           this.zona5 = '0';
       }else{
           this.zona5 = this.form.value.zona5;
       }
       if(this.form.value.zona6 == 0 || this.form.value.zona6 == null){
           this.zona6 = '0';
       }else{
           this.zona6 = this.form.value.zona6;
       }
       if(this.form.value.zona7 == 0 || this.form.value.zona7 == null){
           this.zona7 = '0';
       }else{
           this.zona7 = this.form.value.zona7;
       }
       if(this.form.value.zona8 == 0 || this.form.value.zona8 == null){
           this.zona8 = '0';
       }else{
           this.zona8 = this.form.value.zona8;
       }
       if(this.form.value.zona9 == 0 || this.form.value.zona9 == null){
           this.zona9 = '0';
       }else{
           this.zona9 = this.form.value.zona9;
       }
       if(this.form.value.zona10 == 0 || this.form.value.zona10 == null){
           this.zona10 = '0';
       }else{
           this.zona10 = this.form.value.zona10;
       }
       if(this.form.value.correo != '' || this.form.value.correo != null){
           correo = this.form.value.correo;
       }
       this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("requerimientoId")) {
            this.clientesService.onModificarZonas(
                parseInt(paramMap.get('requerimientoId'),10),
                parseInt(this.form.value.zona1,10),
                parseInt(this.zona2,10),
                parseInt(this.zona3,10),
                parseInt(this.zona4,10),
                parseInt(this.zona5,10),
                parseInt(this.zona6,10),
                parseInt(this.zona7,10),
                parseInt(this.zona8,10),
                parseInt(this.zona9,10),
                parseInt(this.zona10,10)
            ).subscribe(zonaData => {
                this.router.navigate(["/agente/propiedades_relacionadas/"+paramMap.get('requerimientoId')+"/1"]);
            });    
        }});
    this.form.reset();

    
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    this.zonasSub.unsubscribe();
  }
}
