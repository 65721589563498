import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { VisitasService } from "../visitas.service";

@Component({
  selector: "app-detalle-visita",
  templateUrl: "./detalle-visita.component.html",
  styleUrls: ["./detalle-visita.component.css"]
})
export class DetalleVisitaComponent implements OnInit {
    isLoading = false;
    idvisita = '0';
    userIsAuthenticated = false;
    userId: string;

    propiedad: number;
    agente: string;
    fecha: string;
    hora: string;
    comentarioCliente: string;
    oferta: boolean;
    monto: string;
    calificacionCliente: number;
    comentarioAgente: string;
    calificacionAgente: number;

  private authStatusSub: Subscription;


  constructor(
    private authService: AuthService,
    public visitasService: VisitasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("visitaId")) {
            this.idvisita = paramMap.get('visitaId');
            this.visitasService.detalleVisita(this.idvisita).subscribe(visitaData => {
                this.propiedad = visitaData.propiedad;
                this.agente = visitaData.agente;
                this.fecha = visitaData.fecha;
                this.hora = visitaData.hora;
                this.comentarioCliente= visitaData.comentarioCliente;
                this.oferta = visitaData.oferta;
                this.monto = visitaData.monto;
                this.calificacionCliente = visitaData.calificacionCliente;
                this.comentarioAgente = visitaData.comentarioAgente;
                this.calificacionAgente = visitaData.calificacionAgente;
                this.isLoading = false;
            });
        }
    });
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }
  
}
