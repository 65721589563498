import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Producto } from "./producto.model";

import { InventarioService } from "../inventario.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-editar-producto",
  templateUrl: "./editar-producto.component.html",
  styleUrls: ["./editar-producto.component.css"]
})
export class EditarProductoComponent implements OnInit, OnDestroy {
  isLoading = false;
  userIsAuthenticated = false;
  userId: string;
  productoId: string;
  form: FormGroup;
    producto: Producto;
  private authStatusSub: Subscription;

  constructor(
    private authService: AuthService,
    public inventarioService: InventarioService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("productoId")) {
          this.productoId = paramMap.get("productoId");
          this.isLoading = true;
          this.inventarioService.getProducto(this.productoId).subscribe(productoData => {
            this.isLoading = false;
            this.producto = {id: productoData.productos[0].id,nombre: productoData.productos[0].nombre, descripcion: productoData.productos[0].descripcion, precio:  productoData.productos[0].precio,tipo:  productoData.productos[0].tipo};
            console.log(this.producto);
          });
        } else {
          this.productoId = null;
        }
      });

      this.form = new FormGroup({
        nombre: new FormControl(null, {
          validators: [Validators.required, Validators.minLength(3)]
        }),
        descripcion: new FormControl(null, { validators: [Validators.required] }),
        precio: new FormControl(null, { validators: [Validators.required] })
      });

      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }
  
  onEditProducto() {
    if (this.form.invalid) {
      console.log('Ingreso error Producto 3');  
      return;
    }
    this.isLoading = true;
    console.log(this.productoId);
      this.inventarioService.editProducto(
        this.form.value.nombre,
        this.form.value.descripcion,
        this.form.value.precio,
        this.productoId
      );
    this.form.reset();
  }
  

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}


