import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";
import { Chart } from 'chart.js';
import { ReportesService } from "../reportes.service";
import {DatePipe} from '@angular/common';

/*
import { Re } from "../reportes.service";
import { Cliente } from "../clienteAdmin.model";
*/
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-visitas-agentes-admin",
  templateUrl: "./visitas-agentes.component.html",
  styleUrls: ["./visitas-agentes.component.scss"]
})

export class VisitassAgentesAdminComponent implements OnInit, AfterViewInit{

    title = 'Visitas realizadas por los agentes';
    BarChart=[];
    PieChart=[];
    isLoading = false;
    fecha1;
    fecha2;
    fecha3;
    fecha4;
    fecha5;
    fecha6;
    fecha7;
    userId: string;
    userIsAuthenticated = false;
    visitas: any;
    agentes: any;
    nombreAgentes: any = [];
    porsentajeVisitas: any = [];
    private authStatusSub: Subscription;

    constructor(
        public reportesService: ReportesService,
        private authService: AuthService,
        private datePipe: DatePipe
      ) {}

    ngOnInit()
    {   
        //this.isLoading = true;
        this.fecha1 = new Date();
        this.fecha1 = this.datePipe.transform(new Date(),"dd-MM-yyyy");
        this.fecha2 = new Date();
        this.fecha2.setDate( this.fecha2.getDate() - 1 );    
        this.fecha2 = this.datePipe.transform(this.fecha2,"dd-MM-yyyy");
        this.fecha3 = new Date();
        this.fecha3.setDate( this.fecha3.getDate() - 2 );    
        this.fecha3 = this.datePipe.transform(this.fecha3,"dd-MM-yyyy");
        this.fecha4 = new Date();
            
            this.fecha4.setDate( this.fecha4.getDate() - 3 );    
            this.fecha4 = this.datePipe.transform(this.fecha4,"dd-MM-yyyy");
            this.fecha5 = new Date();
            this.fecha5.setDate( this.fecha5.getDate() - 4 );
            this.fecha5 = this.datePipe.transform(this.fecha5,"dd-MM-yyyy");
            this.fecha6 = new Date();
            this.fecha6.setDate( this.fecha6.getDate() - 5 );
            this.fecha6 = this.datePipe.transform(this.fecha6,"dd-MM-yyyy");
            this.fecha7 = new Date();
            this.fecha7.setDate( this.fecha7.getDate() - 6 );
            this.fecha7 = this.datePipe.transform(this.fecha7,"dd-MM-yyyy");
            
        const controlVisitas = this.getVisitas().then(()=>{
        


        });

        

        /*
        this.userId = this.authService.getUserId();

        this.userIsAuthenticated = this.authService.getIsAuth();
        this.authStatusSub = this.authService
        .getAuthStatusListener()
        .subscribe(isAuthenticated => {
            this.userIsAuthenticated = isAuthenticated;
            this.userId = this.authService.getUserId();
        });
        */


        



    }

    ngAfterViewInit() {

    }

    async getVisitas(){
        this.reportesService.getVisitasAgentesSimple().subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.agentes = visitasData.agentes;
            var fechaInicial = new Date();
            

            for(var i=0;i<this.agentes.length;i++){
                this.nombreAgentes.push(this.agentes[i]['agente']);
                this.porsentajeVisitas.push(this.agentes[i]['numero']);
            }
            console.log(this.nombreAgentes);
            console.log(this.porsentajeVisitas);

            // Bar chart:
        this.BarChart = new Chart('barChart', {
            type: 'bar',
            data: {
                labels: [this.fecha7, this.fecha6, this.fecha5, this.fecha4, this.fecha3, this.fecha2,this.fecha1],
            datasets: [{
                label: '# Visitas',
                data: [this.visitas[0]['7'], this.visitas[0]['6'] , this.visitas[0]['5'], this.visitas[0]['4'], this.visitas[0]['3'], this.visitas[0]['2'],this.visitas[0]['1']],
                borderWidth: 1
            }]
            }, 
            options: {
            title:{
                text:"Bar Chart",
                display:false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
            }
            });
    
            // pie chart:
            this.PieChart = new Chart('pieChart', {
            type: 'pie',
            data: {
            labels: this.nombreAgentes,
            datasets: [{
                label: '% de visitas',
                data: this.porsentajeVisitas,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
					'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
					'rgba(255, 99, 132, 0.7)',
                    'rgba(54, 162, 235, 0.7)',
                    'rgba(255, 206, 86, 0.7)',
                    'rgba(75, 192, 192, 0.7)',
                    'rgba(153, 102, 255, 0.7)',
                    'rgba(255, 159, 64, 0.7)',
					'rgba(255, 99, 132, 0.9)',
                    'rgba(54, 162, 235, 0.9)',
                    'rgba(255, 206, 86, 0.9)',
                    'rgba(75, 192, 192, 0.9)',
                    'rgba(153, 102, 255, 0.9)',
                    'rgba(255, 159, 64, 0.9)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
            }, 
            options: {
            title:{
                text:"Bar Chart",
                display:false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
            }
            });
            this.isLoading = false;



            return 0;
        });
    }
}

/*
export class ListaClientesAdminComponent implements OnInit {
    clientes: Cliente[] = [];
    isLoading = false;
    totalClientes = 0;
    clientesPerPage = 10;
    currentPage = 1;
    form: FormGroup;
    pageSizeOptions = [10, 15, 20, 25];
    userIsAuthenticated = false;
    userId: string;
    private authStatusSub: Subscription;
  
    constructor(
      public clientesService: ClienteService,
      private authService: AuthService
    ) {}
  
    ngOnInit() {
      this.isLoading = true;
      this.clientesService.getListaClientesAdmin( this.currentPage -1, this.clientesPerPage).subscribe(clientesData => {
          console.log(clientesData);
          this.clientes = clientesData.clientes;
          console.log(this.clientes);
          this.totalClientes = clientesData.contador;
          this.isLoading = false;
      });
      this.userId = this.authService.getUserId();
        
      this.form = new FormGroup({
          nombre: new FormControl(null,),
        });
  
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.authStatusSub = this.authService
        .getAuthStatusListener()
        .subscribe(isAuthenticated => {
          this.userIsAuthenticated = isAuthenticated;
          this.userId = this.authService.getUserId();
        });
    }
  
    onChangedPage(pageData: PageEvent) {
      this.isLoading = true;  
      this.currentPage = pageData.pageIndex + 1;
      this.clientesPerPage = pageData.pageSize;
      console.log(this.clientesPerPage);
      console.log(this.currentPage);
      console.log(this.form.value.nombre);
      if(this.form.value.nombre == null){
          this.clientesService.getListaClientesAdmin( this.currentPage -1, this.clientesPerPage).subscribe(clientesData => {
              this.clientes = clientesData.clientes;
              this.totalClientes = clientesData.contador;
              this.isLoading = false;
          });
      }else{
          this.clientesService.getListaFiltroClientesAdmin( this.currentPage -1, this.clientesPerPage,this.form.value.nombre).subscribe(clientesData => {
              this.clientes = clientesData.clientes;
              this.totalClientes = clientesData.contador;
              this.isLoading = false;
          });
      }
    }
  
    onEditBusqueda() {
      this.isLoading = true;  
      this.currentPage = 1;
      if(this.form.value.nombre == null){
          this.clientesService.getListaClientesAdmin( this.currentPage -1, this.clientesPerPage).subscribe(clientesData => {
              this.clientes = clientesData.clientes;
              this.totalClientes = clientesData.contador;
              this.isLoading = false;
          });
      }else{
          this.clientesService.getListaFiltroClientesAdmin( this.currentPage -1, this.clientesPerPage,this.form.value.nombre).subscribe(clientesData => {
              this.clientes = clientesData.clientes;
              this.totalClientes = clientesData.contador;
              this.isLoading = false;
          });
      }
    }
  }
*/  