import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Router } from "@angular/router";

import { PropiedadService } from "../propiedades.service";
import { Cliente } from "./cliente.model";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-propiedad-requerimiento",
  templateUrl: "./propiedad-requerimiento.component.html",
  styleUrls: ["./propiedad-requerimiento.component.css"]
})
export class PropiedadRequerimientoComponent implements OnInit {
  clientes: Cliente[] = [];
  isLoading = false;
  miPropiedad = 0;
  userIsAuthenticated = false;
  userId: string;
  private authStatusSub: Subscription;

  constructor(
    public propiedadesService: PropiedadService,
    private authService: AuthService,
    private router: Router,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    console.log('Entramos a buscar requermientos1');
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("propiedadId")) {
            console.log('Entramos a buscar requermientos2');
			this.miPropiedad = parseInt(paramMap.get('propiedadId'));
            this.propiedadesService.getRequerimientosRelacionados(parseInt(paramMap.get('propiedadId'))).subscribe(clientesData => {
                console.log(clientesData);
                this.clientes = clientesData.requerimientos;
                this.isLoading = false;
            });
        }
    });
    console.log(this.clientes);
    
    this.userId = this.authService.getUserId();

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }
}
