import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Producto } from "../producto.model";

import { InventarioService } from "../inventario.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-modificar-cantidad",
  templateUrl: "./modificar-cantidad.component.html",
  styleUrls: ["./modificar-cantidad.component.css"]
})
export class ModificarCantidadComponent implements OnInit, OnDestroy {
  isLoading = false;
  userIsAuthenticated = false;
  userId: string;
  productoId: string;
  form: FormGroup;
    producto: Producto;
  private authStatusSub: Subscription;

  constructor(
    private authService: AuthService,
    public inventarioService: InventarioService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("productoId")) {
            this.productoId = paramMap.get("productoId");
        }else{
            this.productoId = null;
        }
    });

      this.form = new FormGroup({
        cantidad: new FormControl(null, {
          validators: [Validators.required, Validators.minLength(1)]
        }),
        tipo: new FormControl(null, { validators: [Validators.required] })
      });
      this.isLoading = false;

      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }
  
  onEditCantidad() {
    if (this.form.invalid) {
      console.log('Debe llenar todos los campos');  
      return;
    }
    this.isLoading = true;
    console.log(this.productoId);
      this.inventarioService.editCantidad(
        this.form.value.cantidad,
        this.form.value.tipo,
        this.productoId
      );
    this.form.reset();
  }
  
/*  
  onChange(mrChange: MatRadioChange) {
    console.log(mrChange.value);
    let mrButton: MatRadioButton = mrChange.source;
    console.log(mrButton.name);
    console.log(mrButton.checked);
    console.log(mrButton.inputId);
 } 
*/

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}


