import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Post } from './post.model';

@Injectable({providedIn: 'root'})
export class PostsService {
  private posts: Post[] = [];
  private postsUpdated = new Subject<Post[]>();

  constructor (private http: HttpClient){};

  getPosts() {
    this.http.get<{message: String, posts: any}>('http://68.183.168.180:3000/api/posts')
    .pipe(map((postData) => {
        return postData.posts.map(post => {
            return{
                title: post.title,
                content: post.content,
                id: post.id
            }
        })
    }))
    .subscribe(transformedPosts =>{
      this.posts = transformedPosts;
      this.postsUpdated.next([...this.posts]);
    });
  }

  getPost(id: string){
    return {...this.posts.find(p =>p.id === id)};
  }

  getPostUpdateListener() {
    return this.postsUpdated.asObservable();
  }

  addPost(title: string, content: string) {
    const post: Post = {id: null, title: title, content: content};
    this.http
    .post<{ message: string}>("http://68.183.168.180:3000/api/posts",post)
    .subscribe(responseData =>{
        console.log(responseData.message);
        this.posts.push(post);
        this.postsUpdated.next([...this.posts]);
    });
  }

  deletePost(postId: string){
      this.http.delete("http://68.183.168.180:3000/api/delete/" + postId)
      .subscribe(() =>{
          const updatedPosts = this.posts.filter(post => post.id !== postId);
          this.posts = updatedPosts;
          this.postsUpdated.next([...this.posts]);
      });
  }


}