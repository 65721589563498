import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { VisitasService } from "../visitas.service";

@Component({
  selector: "app-registrar-visita-instantanea",
  templateUrl: "./registrar-visita-instantanea.component.html",
  styleUrls: ["./registrar-visita-instantanea.component.css"]
})
export class RegistrarVisitaInstantaneaComponent implements OnInit {
  isLoading = false;
  isPropiedadValida = false;
  isPropiedaBusqueda = false;
  isLoadingPropiedad = false;
  empresaValida = false;
  clienteId = '0';
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;

  propiedad: number;
  agente: string;
  fecha: string;
  hora: string;

  private authStatusSub: Subscription;


  constructor(
    private authService: AuthService,
    public visitasService: VisitasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        propiedad: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        fecha: new FormControl(null, { validators: [Validators.required] }),
        hora: new FormControl(null, { validators: [Validators.required] }),
        comentarioCliente: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        oferta: new FormControl(null, { validators: [Validators.required] }),
        monto: new FormControl(null, { }),
        calificacionCliente: new FormControl(null, { validators: [Validators.required] }),
		representado: new FormControl(null, { }),
		representadoNombre: new FormControl(null, { }),
		representadoEmail: new FormControl(null, { }),
		representadoCelular: new FormControl(null, { }),
		representadoTelefono: new FormControl(null, { }),
      });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("clienteId")) {
            this.clienteId = paramMap.get('clienteId');
            this.isLoading = false;
        }
    });
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  onRegistrarEvaluacion(){
    this.isLoading = true;  
    if (this.form.invalid) {
        this.isLoading = false;  
        return;
    }
	
	console.log('estado oferta');
	console.log(this.form.value.oferta);
    var montoOferta: any  = '0';
    if(this.form.value.oferta == '1')
    montoOferta = this.form.value.monto;
    
	console.log('El total de la oferta');
	console.log(montoOferta);
	
	var representado: string = '2';
	var representadoNombre: string = '0';
	var representadoEmail: string = '0';
	var representadoCelular: string = '0';
	var representadoTelefono: string = '0';
	if(this.form.value.representado == '1'){
		representado = this.form.value.representado;
		representadoNombre = this.form.value.representadoNombre;
		representadoEmail = this.form.value.representadoEmail;
		representadoCelular = this.form.value.representadoCelular;
		representadoTelefono = this.form.value.representadoTelefono;
	}    

        this.visitasService.registrarEvaluacionInstantanea(parseInt(this.clienteId,10),this.form.value.comentarioCliente,this.form.value.oferta,montoOferta,this.form.value.calificacionCliente,
        this.form.value.propiedad,this.form.value.fecha,this.form.value.hora,representado,representadoNombre,representadoEmail,representadoCelular,representadoTelefono).subscribe(visita => {
            alert('Visita Registrada');
            this.router.navigate(["/visitas/historial"]);
            this.isLoading = false;  
        },
        error => {
            alert('Error en la Solicitud');
            this.isLoading = false;  
        });
  }

  onValidarPropiedad(deviceValue){
    if(parseInt(deviceValue,10)>999){
      this.isLoadingPropiedad = true;
      this.isPropiedadValida = false;
      this.isPropiedaBusqueda = false;
      this.visitasService.validarPropiedad(deviceValue).subscribe(propiedad => {
          console.log(propiedad.error);
          this.isPropiedaBusqueda = true;
          if(propiedad.error == true){
              console.log('no encontrada');
              this.isPropiedadValida = false;  
          }else if(propiedad.error == false){
              console.log('encontrada');
              this.isPropiedadValida = true;
          }
          console.log(this.isPropiedadValida);
          this.isLoadingPropiedad = false;
      });
    }
}
  
}
