import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { Propiedad } from "../propiedad.model";
import { PropiedadEnviada } from "../propiedades_enviadas.model";
import { AuthService } from "../../auth/auth.service";
import { ClienteService } from "../cliente.service";

@Component({
  selector: "app-propiedades-relacionadas",
  templateUrl: "./propiedades_relacionadas.component.html",
  styleUrls: ["./propiedades_relacionadas.component.scss"]
})
export class PropiedadesRelacionadasComponent implements OnInit, OnDestroy {
  totalPropiedades = 1;
  modificarFiltro = false;
  cantidad = 0;
  propiedadesPerPage = 50;
  currentPage = 1;
  pageSizeOptions = [50];
  isLoading = false;
  errorRepetido = false;
  exitoAgregado = false;
  idRequerimiento: string;
  form: FormGroup;
  filtro: FormGroup;
  userIsAuthenticated = false;
  propiedadesEnviados: PropiedadEnviada[] = [];
  userId: string;
  propiedades: Propiedad[];
  temporal: any [];
  celular: string;
  descripcion: string;
  validar = false;
  ifEnviado = [];
  propiedadesEnviadas: string[];
  direcion = "https://api.whatsapp.com/send?phone=";
  private authStatusSub: Subscription;
  private propiedadesSub: Subscription;


  constructor(
    private authService: AuthService,
    public clientesService: ClienteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
      console.log('Entramos al inicio');
    this.isLoading = true;

    this.filtro = new FormGroup({
        precioMax: new FormControl(null, { }),
        dormitoriosMin: new FormControl(null, { }),
        banosMin: new FormControl(null, { }),
        garajesMin: new FormControl(null, { }),
        construidoMin: new FormControl(null, { }),
        terrenoMin: new FormControl(null, { }),
    });

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        var temporalVistas
        if (paramMap.has("idRequerimiento")) {
            this.idRequerimiento = paramMap.get('idRequerimiento');


            this.clientesService.getTelefonoRequerimiento(this.idRequerimiento).subscribe(telefonoData => {
                this.isLoading = false;
                this.celular = telefonoData.telefono;
            });


            this.clientesService.getPropiedadesEnviadas(paramMap.get('idRequerimiento')).subscribe(propiedadesEnviadasData => {
                if(propiedadesEnviadasData['propiedades']=='[{}]' ||  propiedadesEnviadasData['propiedades']=='[]'||  propiedadesEnviadasData['propiedades'].length==0){
                    
                }else{
                    this.propiedadesEnviados= JSON.parse(propiedadesEnviadasData['propiedades'][0]['propiedades']);
                }
            });
			this.clientesService.getPropiedadesRelacionadas( paramMap.get('idRequerimiento')).subscribe(propiedadesData => {
				this.clientesService.getCaracteristicas(paramMap.get('idRequerimiento')).subscribe(requerimientoData => {
                    this.filtro.controls['precioMax'].setValue(requerimientoData['precioMax']);
                    this.filtro.controls['dormitoriosMin'].setValue(requerimientoData['habitacionesMin']);
                    this.filtro.controls['banosMin'].setValue(requerimientoData['banosMin']);
                    this.filtro.controls['garajesMin'].setValue(requerimientoData['garajesMin']);
                    this.filtro.controls['construidoMin'].setValue(requerimientoData['construidoMin']);
                    this.filtro.controls['terrenoMin'].setValue(requerimientoData['terrenoMin']);
                    this.descripcion=requerimientoData['descripcion'];
                    this.isLoading = false;
					
					this.totalPropiedades = propiedadesData.cantidad;
                    this.propiedades = propiedadesData.propiedades;
                    for(let i=0;i<this.propiedades.length;i++){
                        var temporal = this.propiedades[i].id;
                        var identificador = this.propiedadesEnviados.filter(function(item) {
                            return item.p === temporal;
                            });
                            if(identificador.length==0){
                                this.ifEnviado[i]="No Enviado";
                            }else{
                                this.ifEnviado[i]="Enviado";
                            }
                    }
                });
			});
			
			/*
            this.clientesService.getPropiedadesRelacionadas(paramMap.get('idRequerimiento'));
            this.propiedadesSub = this.clientesService
            .getPropiedadesRelacionadasListener()
            .subscribe((PropiedadesRelacionadasData: { propiedades: Propiedad[], cantidad }) => {
                this.clientesService.getCaracteristicas(paramMap.get('idRequerimiento')).subscribe(requerimientoData => {
                    this.filtro.controls['precioMax'].setValue(requerimientoData['precioMax']);
                    this.filtro.controls['dormitoriosMin'].setValue(requerimientoData['habitacionesMin']);
                    this.filtro.controls['banosMin'].setValue(requerimientoData['banosMin']);
                    this.filtro.controls['garajesMin'].setValue(requerimientoData['garajesMin']);
                    this.filtro.controls['construidoMin'].setValue(requerimientoData['construidoMin']);
                    this.filtro.controls['terrenoMin'].setValue(requerimientoData['terrenoMin']);
                    this.descripcion=requerimientoData['descripcion'];
                    this.isLoading = false;
					console.log('Cantidad de propiedades' + PropiedadesRelacionadasData.cantidad);
					this.totalPropiedades = PropiedadesRelacionadasData.cantidad;
                    this.propiedades = PropiedadesRelacionadasData.propiedades;
                    for(let i=0;i<this.propiedades.length;i++){
                        var temporal = this.propiedades[i].id;
                        var identificador = this.propiedadesEnviados.filter(function(item) {
                            return item.p === temporal;
                            });
                            if(identificador.length==0){
                                this.ifEnviado[i]="No Enviado";
                            }else{
                                this.ifEnviado[i]="Enviado";
                            }
                    }
                });
            });
			*/
			
			
			
			
			
        }
    });
    console.log(this.ifEnviado);

    this.form = new FormGroup({
        propiedad0: new FormControl(null, { }),
        propiedad1: new FormControl(null, { }),
        propiedad2: new FormControl(null, { }),
        propiedad3: new FormControl(null, { }),
        propiedad4: new FormControl(null, { }),
        propiedad5: new FormControl(null, { }),
        propiedad6: new FormControl(null, { }),
        propiedad7: new FormControl(null, { }),
        propiedad8: new FormControl(null, { }),
        propiedad9: new FormControl(null, { }),
        propiedad10: new FormControl(null, { }),
        propiedad11: new FormControl(null, { }),
        propiedad12: new FormControl(null, { }),
        propiedad13: new FormControl(null, { }),
        propiedad14: new FormControl(null, { }),
        propiedad15: new FormControl(null, { }),
        propiedad16: new FormControl(null, { }),
        propiedad17: new FormControl(null, { }),
        propiedad18: new FormControl(null, { }),
        propiedad19: new FormControl(null, { }),
        propiedad20: new FormControl(null, { }),
        propiedad21: new FormControl(null, { }),
        propiedad22: new FormControl(null, { }),
        propiedad23: new FormControl(null, { }),
        propiedad24: new FormControl(null, { }),
        propiedad25: new FormControl(null, { }),
        propiedad26: new FormControl(null, { }),
        propiedad27: new FormControl(null, { }),
        propiedad28: new FormControl(null, { }),
        propiedad29: new FormControl(null, { }),
        propiedad30: new FormControl(null, { }),
        propiedad31: new FormControl(null, { }),
        propiedad32: new FormControl(null, { }),
        propiedad33: new FormControl(null, { }),
        propiedad34: new FormControl(null, { }),
        propiedad35: new FormControl(null, { }),
        propiedad36: new FormControl(null, { }),
        propiedad37: new FormControl(null, { }),
        propiedad38: new FormControl(null, { }),
        propiedad39: new FormControl(null, { }),
        propiedad40: new FormControl(null, { }),
        propiedad41: new FormControl(null, { }),
        propiedad42: new FormControl(null, { }),
        propiedad43: new FormControl(null, { }),
        propiedad44: new FormControl(null, { }),
        propiedad45: new FormControl(null, { }),
        propiedad46: new FormControl(null, { }),
        propiedad47: new FormControl(null, { }),
        propiedad48: new FormControl(null, { }),
        propiedad49: new FormControl(null, { }),
        propiedad50: new FormControl(null, { }),
        propiedad51: new FormControl(null, { }),
        propiedad52: new FormControl(null, { }),
        propiedad53: new FormControl(null, { }),
        propiedad54: new FormControl(null, { }),
        propiedad55: new FormControl(null, { }),
        propiedad56: new FormControl(null, { }),
        propiedad57: new FormControl(null, { }),
        propiedad58: new FormControl(null, { }),
        propiedad59: new FormControl(null, { }),
        propiedad60: new FormControl(null, { }),
        propiedad61: new FormControl(null, { }),
        propiedad62: new FormControl(null, { }),
        propiedad63: new FormControl(null, { }),
        propiedad64: new FormControl(null, { }),
        propiedad65: new FormControl(null, { }),
        propiedad66: new FormControl(null, { }),
        propiedad67: new FormControl(null, { }),
        propiedad68: new FormControl(null, { }),
        propiedad69: new FormControl(null, { }),
        propiedad70: new FormControl(null, { }),
      });
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  onEditFiltro(){
    console.log('Entramos al editar');
    this.isLoading = true;
	this.modificarFiltro = true;
    var dormitoriosMin =0;
    var banosMin =0;
    var garajesMin = 0;
    var construidoMin = 0;
    var terrenoMin = 0;
    var precioMax = "0";
    if(this.filtro.value.dormitoriosMin){
        dormitoriosMin = this.filtro.value.dormitoriosMin;
    }
    if(this.filtro.value.banosMin){
        banosMin = this.filtro.value.banosMin;
    }
    if(this.filtro.value.garajesMin){
        garajesMin = this.filtro.value.garajesMin;
    }
    if(this.filtro.value.construidoMin){
        construidoMin = this.filtro.value.construidoMin;
    }
    if(this.filtro.value.terrenoMin){
        terrenoMin = this.filtro.value.terrenoMin;
    }
    if(this.filtro.value.precioMax){
        precioMax = this.filtro.value.precioMax;
    }
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("idRequerimiento")) {
            this.clientesService.modificarRequerimiento(dormitoriosMin,banosMin,garajesMin,construidoMin,terrenoMin,precioMax,paramMap.get('idRequerimiento')).subscribe(requerimientoData => {
                this.route.paramMap.subscribe((paramMap: ParamMap) => {
                    if (paramMap.has("idRequerimiento")) {
						this.clientesService.getPropiedadesRelacionadas( paramMap.get('idRequerimiento')).subscribe(propiedadesData => {
							this.isLoading = false;
							this.modificarFiltro = false;
                            this.propiedades = propiedadesData.propiedades;
							this.cantidad = propiedadesData.cantidad;
							this.totalPropiedades = propiedadesData.cantidad;
						});
                    }
                });
            });
        }
    });
  }

  enviarPropiedad(id: number){

    this.route.paramMap.subscribe((paramMap: ParamMap) => {


        this.direcion = "https://api.whatsapp.com/send?phone=";
        this.isLoading = true;
        this.direcion = this.direcion+"591"+this.celular+"&text=https://firmacasas.com/?p%3D"+id+"%26agente%3D"+this.userId;
        window.open(this.direcion, "_blank"); 

        var identificador = this.propiedadesEnviados.filter(function(item) {
            return item.p === id;
        });
        if(identificador.length==0){
            this.propiedadesEnviados.push({"p":id});
                if (paramMap.has("idRequerimiento")) {
                        this.clientesService.modificarPropiedadesEnviadas(paramMap.get('idRequerimiento'),JSON.stringify(this.propiedadesEnviados)).subscribe(requerimientoData => {
                    });
                }
            this.errorRepetido=false;
            this.exitoAgregado = true;
        }else{
            this.errorRepetido=true;
            this.exitoAgregado = false;
        }
        for(let i=0;i<this.propiedades.length;i++){
            var temporal = this.propiedades[i].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
            });
            if(identificador.length==0){
                this.ifEnviado[i]="No Enviado";
            }else{
                this.ifEnviado[i]="Enviado";
            }
        }
        this.isLoading = false;
        
    });
  }

  enviarPropiedades(){
    this.direcion = "https://api.whatsapp.com/send?phone=";
    this.validar = false;
    this.isLoading = true;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {

    });
        this.isLoading = false;
        
        this.direcion = this.direcion+"591"+this.celular+"&text=Propiedades%20encontradas%20";
    
        if(this.form.value.propiedad0){
            var temporal = this.propiedades[0].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
            return item.p === temporal;
            });
            if(identificador.length==0){
              this.propiedadesEnviados.push({"p":this.propiedades[0].id});
            }

            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[0].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
    
        if(this.form.value.propiedad1){
            var temporal = this.propiedades[1].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[1].id});
                }

            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[1].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad2){
            var temporal = this.propiedades[2].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[2].id});
                }

            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[2].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad3){
            var temporal = this.propiedades[3].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[3].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[3].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad4){
            var temporal = this.propiedades[4].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[4].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[4].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad5){
            var temporal = this.propiedades[5].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[5].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[5].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad6){
            var temporal = this.propiedades[6].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[6].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[6].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad7){
            var temporal = this.propiedades[7].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[7].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[7].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad8){
            var temporal = this.propiedades[8].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[8].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[8].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad9){
            var temporal = this.propiedades[9].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[9].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[9].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad10){
            var temporal = this.propiedades[10].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[10].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[10].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad11){
            var temporal = this.propiedades[11].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[11].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[11].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad12){
            var temporal = this.propiedades[12].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[12].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[12].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad13){
            var temporal = this.propiedades[13].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[13].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[13].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad14){
            var temporal = this.propiedades[14].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[14].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[14].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad15){
            var temporal = this.propiedades[15].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[15].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[15].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad16){
            var temporal = this.propiedades[16].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[16].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[16].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad17){
            var temporal = this.propiedades[17].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[17].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[17].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad18){
            var temporal = this.propiedades[18].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[18].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[18].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad19){
            var temporal = this.propiedades[19].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[19].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[19].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad20){
            var temporal = this.propiedades[20].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[20].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[20].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad21){
            var temporal = this.propiedades[21].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[21].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[21].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad22){
            var temporal = this.propiedades[22].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[22].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[22].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad23){
            var temporal = this.propiedades[23].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[23].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[23].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad24){
            var temporal = this.propiedades[24].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[24].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[24].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad25){
            var temporal = this.propiedades[25].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[25].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[25].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad26){
            var temporal = this.propiedades[26].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[26].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[26].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad27){
            var temporal = this.propiedades[27].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[27].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[27].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad28){
            var temporal = this.propiedades[28].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[28].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[28].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad29){
            var temporal = this.propiedades[29].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[29].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[29].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad30){
            var temporal = this.propiedades[30].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[30].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[30].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad31){
            var temporal = this.propiedades[31].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[31].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[31].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad32){
            var temporal = this.propiedades[32].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[32].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[32].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad33){
            var temporal = this.propiedades[33].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[33].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[33].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad34){
            var temporal = this.propiedades[34].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[34].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[34].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad35){
            var temporal = this.propiedades[35].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[35].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[35].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad36){
            var temporal = this.propiedades[36].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[36].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[36].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad37){
            var temporal = this.propiedades[37].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[37].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[37].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad38){
            var temporal = this.propiedades[38].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[38].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[38].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad39){
            var temporal = this.propiedades[39].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[39].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[39].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad40){
            var temporal = this.propiedades[40].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[40].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[40].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad41){
            var temporal = this.propiedades[41].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[41].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[41].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad42){
            var temporal = this.propiedades[42].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[42].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[42].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad43){
            var temporal = this.propiedades[43].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[43].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[43].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad44){
            var temporal = this.propiedades[44].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[44].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[44].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad45){
            var temporal = this.propiedades[45].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[45].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[45].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad46){
            var temporal = this.propiedades[46].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[46].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[46].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad47){
            var temporal = this.propiedades[47].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[47].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[47].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad48){
            var temporal = this.propiedades[48].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[48].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[48].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad49){
            var temporal = this.propiedades[49].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[49].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[49].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad50){
            var temporal = this.propiedades[50].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[50].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[50].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad51){
            var temporal = this.propiedades[51].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[51].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[51].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad52){
            var temporal = this.propiedades[52].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[52].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[52].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad53){
            var temporal = this.propiedades[53].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[53].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[53].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad54){
            var temporal = this.propiedades[54].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[54].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[54].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad55){
            var temporal = this.propiedades[55].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[55].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[55].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad56){
            var temporal = this.propiedades[56].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[56].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[56].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad57){
            var temporal = this.propiedades[57].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[57].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[57].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad58){
            var temporal = this.propiedades[58].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.form.value.propiedad58});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[58].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad59){
            var temporal = this.propiedades[59].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[59].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[59].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad60){
            var temporal = this.propiedades[60].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[60].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[60].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad61){
            var temporal = this.propiedades[61].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[61].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[61].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad62){
            var temporal = this.propiedades[62].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[62].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[62].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad63){
            var temporal = this.propiedades[63].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[63].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[63].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad64){
            var temporal = this.propiedades[64].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[64].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[64].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad65){
            var temporal = this.propiedades[65].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[65].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[65].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad66){
            var temporal = this.propiedades[66].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[66].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[66].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad67){
            var temporal = this.propiedades[67].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[67].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[67].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad68){
            var temporal = this.propiedades[68].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[68].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[68].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad69){
            var temporal = this.propiedades[69].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.form.value.propiedad69});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[69].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }
        if(this.form.value.propiedad70){
            var temporal = this.propiedades[70].id;
            var identificador = this.propiedadesEnviados.filter(function(item) {
                return item.p === temporal;
                });
                if(identificador.length==0){
                  this.propiedadesEnviados.push({"p":this.propiedades[70].id});
                }
            this.direcion = this.direcion+"https://firmacasas.com/?p%3D"+this.propiedades[70].id+"%26agente%3D"+this.userId+"  -  ";
            this.validar = true;
        }

        if(this.validar== true){
            this.route.paramMap.subscribe((paramMap: ParamMap) => {
                if (paramMap.has("idRequerimiento")) {
                    this.clientesService.modificarPropiedadesEnviadas(paramMap.get('idRequerimiento'),JSON.stringify(this.propiedadesEnviados)).subscribe(requerimientoData => {
                    });
                }
            });
            for(let i=0;i<this.propiedades.length;i++){
                var temporal = this.propiedades[i].id;
                var identificador = this.propiedadesEnviados.filter(function(item) {
                    return item.p === temporal;
                    });
                    if(identificador.length==0){
                        this.ifEnviado[i]="No Enviado";
                    }else{
                        this.ifEnviado[i]="Enviado";
                    }
            }
            console.log(this.propiedadesEnviados);
            window.open(this.direcion, "_blank"); 
        }
      
  }
  
  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
  
  onChangedPage(pageData: PageEvent) {
    this.isLoading = true;  
	console.log("numero paginas");
	console.log(this.currentPage);
    this.currentPage = pageData.pageIndex + 1;
    console.log(this.currentPage);
        this.clientesService.getPropiedadesRelacionadasPorPagina( this.idRequerimiento, this.currentPage -1).subscribe(propiedadesData => {
			this.totalPropiedades = propiedadesData.cantidad;
			this.propiedades = propiedadesData.propiedades;
			this.isLoading = false;
        });
    
  }
}
