import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

import { Propiedad } from "./mis-propiedades/propiedad.model";
//import { Usuario } from "./lista-usuarios/usuario.model";


@Injectable({ providedIn: "root" })
export class PropiedadService {
  private propiedades: Propiedad[] = [];
  
  private PropiedadesRelacionadasUpdated = new Subject<{ propiedades: Propiedad[] }>();
  /*
  private sucursalesUpdated = new Subject<{ sucursales: Sucursal[] }>();
  private usuariosUpdated = new Subject<{ usuarios: Usuario[] }>();
  */
  constructor(private http: HttpClient, private router: Router) {}
   
   getPropiedades(){
    return this.http.get<{
        error: boolean;
        propiedades: any;
    }>("http://199.192.16.143:3000/api/propiedades/mis-propiedades");   
   }

   getRequerimientosRelacionados(id: number){
    return this.http.get<{
        error: boolean;
        requerimientos: any;
    }>("http://199.192.16.143:3000/api/propiedades/requerimientos/" + id);   
   }
   
}
