import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

import { Zona } from "./zona.model";
import { Propiedad } from "./propiedad.model";
//import { Usuario } from "./lista-usuarios/usuario.model";

@Injectable({ providedIn: "root" })
export class ClienteService {
  private zonas: Zona[] = [];
  private propiedades: Propiedad[] = [];
  private cantidad: number;
  //private usuarios: Usuario[] = [];
  
  
  private zonasUpdated = new Subject<{ zonas: Zona[] }>();
  private PropiedadesRelacionadasUpdated = new Subject<{ propiedades: Propiedad[] }>();
  /*
  private sucursalesUpdated = new Subject<{ sucursales: Sucursal[] }>();
  private usuariosUpdated = new Subject<{ usuarios: Usuario[] }>();
  */
  constructor(private http: HttpClient, private router: Router) {}

getZonas(ciudad: string) {
    this.http
      .get<{ elError: boolean; zonas: any  }>(
        "http://199.192.16.143:3000/api/zonas/" + ciudad
      )
      .pipe(
        map(zonaData => {
          return {
            zonas: zonaData.zonas.map(zona => {
              return {
                id: zona.id,
                nombre: zona.nombre,
              };
            })
          };
        })
      )
      .subscribe(transformedZonaData => {
        this.zonas = transformedZonaData.zonas;
        this.zonasUpdated.next({
          zonas: [...this.zonas]
        });
      });
  }

  getZonaUpdateListener() {
    return this.zonasUpdated.asObservable();
  }

  getZonasModificar(id: number) {
    this.http
      .get<{ error: boolean; zonas: any  }>(
        "http://199.192.16.143:3000/api/zonas/requerimiento/" + id
      )
      .pipe(
        map(zonaData => {
          return {
            zonas: zonaData.zonas.map(zona => {
              return {
                id: zona.id,
                nombre: zona.nombre,
              };
            })
          };
        })
      )
      .subscribe(transformedZonaData => {
        this.zonas = transformedZonaData.zonas;
        this.zonasUpdated.next({
          zonas: [...this.zonas]
        });
      });
  }

  getZonaModificarUpdateListener() {
    return this.zonasUpdated.asObservable();
  }

  getZonasRequerimiento(requerimiento: number){
    return this.http.get<{
        error:boolean,
        zona1:number,
		zona2:number,
		zona3:number,
		zona4:number,
		zona5:number,
		zona6:number,
		zona7:number,
		zona8:number,
		zona9:number,
		zona10:number,
    }>("http://199.192.16.143:3000/api/zonas/requerimiento/obtner/" + requerimiento);
  }

  onModificarZonas(requerimiento:number, zona1:number, zona2:number, zona3:number, zona4:number, zona5:number, zona6:number, zona7:number, zona8:number, zona9:number, zona10:number){
    return this.http
    .post<{ error: boolean;mensaje:string}>(
      "http://199.192.16.143:3000/api/zonas/requerimiento/modificar",
        {
            requerimiento:requerimiento,
            zona1:zona1,
            zona2:zona2,
            zona3:zona3,
            zona4:zona4,
            zona5:zona5,
            zona6:zona6,
            zona7:zona7,
            zona8:zona8,
            zona9:zona9,
            zona10:zona10
        }
    )
  }
  
  getPropiedadesRelacionadas(requerimiento: string) {
    return this.http
      .get<{ elError: boolean; propiedades: any; cantidad: any; }>(
        "http://199.192.16.143:3000/api/clientes/propiedades/" + requerimiento
      )
  }

  getPropiedadesRelacionadasPorPagina(requerimiento: string, pagina: any) {
	  console.log("Numero pagina:" + pagina);
    return this.http
      .get<{ elError: boolean; propiedades: any; cantidad: any; }>(
        "http://199.192.16.143:3000/api/clientes/propiedades/" + requerimiento + "/" + pagina
      )
  }



  addCliente(nombre: string | Blob,
    apellidoP: string | Blob,
    apellidoM: string | Blob,
    celular: string | Blob,
    correo: string | Blob,
    descripcion: string | Blob,
    fuente: string | Blob,
    estado: string | Blob,
    tipo: string | Blob,
    precioMax: string | Blob,
    ciudad: string | Blob,
    zona1: string | Blob,
    zona2: string | Blob,
    zona3: string | Blob,
    zona4: string | Blob,
    zona5: string | Blob,
    zona6: string | Blob,
    zona7: string | Blob,
    zona8: string | Blob,
    zona9: string | Blob,
    zona10: string | Blob) {
    
    this.http
      .post<{ idRequerimiento: number; mensaje: string;}>(
        "http://199.192.16.143:3000/api/clientes/agregar",
        {
            nombre: nombre,
            apellidoP:apellidoP,
            apellidoM:apellidoM,
            celular:celular,
            correo:correo,
            descripcion:descripcion,
            fuente:fuente,
            estado:estado,
            tipo:tipo,
            precioMax:precioMax,
            ciudad:ciudad,
            zona1:zona1,
            zona2:zona2,
            zona3:zona3,
            zona4:zona4,
            zona5:zona5,
            zona6:zona6,
            zona7:zona7,
            zona8:zona8,
            zona9:zona9,
            zona10:zona10
            }
      )
      .subscribe(responseData => {
        this.router.navigate(["/agente/propiedades_relacionadas/"+responseData.idRequerimiento+"/1"]);
      });
  }

  getTelefono() {
    return this.http.get<{
      error: string;
      telefono: string
    }>("http://199.192.16.143:3000/api/agente/telefono");
  }

  getTelefonoRequerimiento(requerimiento: string) {
    return this.http.get<{
      error: string;
      telefono: string
    }>("http://199.192.16.143:3000/api/agente/telefono/"+requerimiento);
  }

  getPropiedadesEnviadas(requerimiento: string) {
    return this.http.get<{
      error: string;
      propiedades: string
    }>("http://199.192.16.143:3000/api/agente/propiedades/enviadas/"+ requerimiento);
  }

  modificarPropiedadesEnviadas(requerimiento: string,propiedades: string){
    return this.http
    .post<{ error: boolean; mensaje: string;}>(
      "http://199.192.16.143:3000/api/agente/propiedades/enviadas/registro",
      {
        requerimiento:requerimiento,
        propiedades:propiedades
      }
    )
  }
  

  getCaracteristicas(requerimiento: string) {
    return this.http.get<{
        tipoInmueble:string;
        estadoInmueble:string;
        precioMax:string;
        habitacionesMin:number;
        banosMin:number;
        garajesMin:number;
        construidoMin:number;
        terrenoMin:number;
        descripcion:string;
    }>("http://199.192.16.143:3000/api/agente/requerimiento/caracteristicas/" + requerimiento);
  }

  modificarRequerimiento(dormitoriosMin: number,banosMin: number,garajesMin: number,construidoMin: number,terrenoMin: number,precioMax: string | Blob,requerimiento:  string | Blob){
    return this.http
    .post<{ error: boolean; mensaje: string;}>(
      "http://199.192.16.143:3000/api/agente/modificar_requerimiento",
      {
        dormitoriosMin: dormitoriosMin,
        banosMin: banosMin,
        garajesMin:garajesMin,
        construidoMin:construidoMin,
        terrenoMin:terrenoMin,
        precioMax:precioMax,
        requerimiento:requerimiento
      }
    )
  }
  getNombreAgente(celular: string | Blob){
    return this.http.get<{
        nombre:string;
    }>("http://199.192.16.143:3000/api/agente/cliente/duplicado/" + celular);
  }

  getListaClientes(paginaActual: number, clientesPorPagina: number){
    return this.http.get<{
        clientes:any;
        contador:number;
    }>("http://199.192.16.143:3000/api/agente/clientes/" + paginaActual + '/' + clientesPorPagina);   
  }

  getListaFiltroClientes(paginaActual: number, clientesPorPagina: number, nombre:string){
    return this.http.get<{
        clientes:any;
        contador:number;
    }>("http://199.192.16.143:3000/api/agente/clientes/" + nombre + '/' + paginaActual + '/' + clientesPorPagina);   
  }

  getListaRequerimientos(paginaActual: number, clientesPorPagina: number, id: string){
    return this.http.get<{
        requerimientos:any;
        contador:number;
    }>("http://199.192.16.143:3000/api/agente/cliente/requerimientos/" + paginaActual + '/' + clientesPorPagina + '/' + id);   
  }
  modificarCliente(
    id:  string | Blob,
    nombre : string | Blob,
    apellidoP: string | Blob,
    apellidoM: string | Blob,
    celular1: string | Blob,
    celular2: string | Blob,
    telefono1: string | Blob,
    telefono2: string | Blob,
    correo: string | Blob,
    nacionalidad: string | Blob,
    ci: string | Blob,
    genero: string | Blob,
    nacimiento: string | Blob,
   ){
       console.log("Entramos a modificar");
    return this.http
    .post<{ error: boolean; mensaje: string;}>(
      "http://199.192.16.143:3000/api/agente/cliente/modificar",
      {
        id:id,
        nombre : nombre,
        apellidoP: apellidoP,
        apellidoM: apellidoM,
        celular1: celular1,
        celular2: celular2,
        telefono1: telefono1,
        telefono2: telefono2,
        correo: correo,
        nacionalidad: nacionalidad,
        ci: ci,
        genero: genero,
        nacimiento: nacimiento
      }
    );
   }
   getCliente(id:  string | Blob,){
    return this.http.get<{
        cliente:any;
    }>("http://199.192.16.143:3000/api/agente/cliente/" + id);   
   }

   addRequerimiento(id: string | Blob,   
    descripcion: string | Blob,
    fuente: string | Blob,
    estado: string | Blob,
    tipo: string | Blob,
    precioMax: string | Blob,
    ciudad: string | Blob,
    zona1: string | Blob,
    zona2: string | Blob,
    zona3: string | Blob,
    zona4: string | Blob,
    zona5: string | Blob,
    zona6: string | Blob,
    zona7: string | Blob,
    zona8: string | Blob,
    zona9: string | Blob,
    zona10: string | Blob){
       return this.http
      .post<{ idRequerimiento: number; mensaje: string;}>(
        "http://199.192.16.143:3000/api/cliente/requerimiento/agregar",
        {
            id: id,
            descripcion:descripcion,
            fuente:fuente,
            estado:estado,
            tipo:tipo,
            precioMax:precioMax,
            ciudad:ciudad,
            zona1:zona1,
            zona2:zona2,
            zona3:zona3,
            zona4:zona4,
            zona5:zona5,
            zona6:zona6,
            zona7:zona7,
            zona8:zona8,
            zona9:zona9,
            zona10:zona10
            }
      )
   }

   getAgentes(){
    return this.http
    .get<{ agentes: any}>(
      "http://199.192.16.143:3000/api/admin/agentes",
      {}
    )
   }
   
   asignarCliente(nombre: string | Blob,
    apellidoP: string | Blob,
    apellidoM: string | Blob,
    celular: string | Blob,
    correo: string | Blob,
	agente: string | Blob,
    descripcion: string | Blob,
    fuente: string | Blob,
    estado: string | Blob,
    tipo: string | Blob,
    precioMax: string | Blob,
	habitacionesMin: number,
	banosMin: number,
	garajesMin: number,
	construidoMin: number,
	terrenoMin: number,
    ciudad: string | Blob,
    zona1: string | Blob,
    zona2: string | Blob,
    zona3: string | Blob,
    zona4: string | Blob,
    zona5: string | Blob,
    zona6: string | Blob,
    zona7: string | Blob,
    zona8: string | Blob,
    zona9: string | Blob,
    zona10: string | Blob) {
    
    return this.http
      .post<{ idRequerimiento: number; mensaje: string;}>(
        "http://199.192.16.143:3000/api/admin/cliente/asignar",
        {
            nombre: nombre,
            apellidoP:apellidoP,
            apellidoM:apellidoM,
            celular:celular,
            correo:correo,
			agente:agente,
            descripcion:descripcion,
            fuente:fuente,
            estado:estado,
            tipo:tipo,
            precioMax:precioMax,
			habitacionesMin:habitacionesMin,
			banosMin:banosMin,
			garajesMin:garajesMin,
			construidoMin:construidoMin,
			terrenoMin:terrenoMin,
            ciudad:ciudad,
            zona1:zona1,
            zona2:zona2,
            zona3:zona3,
            zona4:zona4,
            zona5:zona5,
            zona6:zona6,
            zona7:zona7,
            zona8:zona8,
            zona9:zona9,
            zona10:zona10
            }
      )   
   }

   asignarClienteCodigo(nombre: string | Blob,
    apellidoP: string | Blob,
    apellidoM: string | Blob,
    celular: string | Blob,
    correo: string | Blob,
	agente: string | Blob,
    descripcion: string | Blob,
    fuente: string | Blob,
    propiedad: string | Blob,
    ) {
    return this.http
      .post<{ idRequerimiento: number; mensaje: string;}>(
        "http://199.192.16.143:3000/api/admin/cliente/asignar-codigo",
        {
            nombre: nombre,
            apellidoP:apellidoP,
            apellidoM:apellidoM,
            celular:celular,
            correo:correo,
			agente:agente,
            descripcion:descripcion,
            fuente:fuente,
            propiedad: propiedad
            }
      )   
   }
   
   getListaClientesAdmin(paginaActual: number, clientesPorPagina: number){
    return this.http.get<{
        clientes:any;
        contador:number;
    }>("http://199.192.16.143:3000/api/admin/clientes/" + paginaActual + '/' + clientesPorPagina);   
   }
   
   getListaFiltroClientesAdmin(paginaActual: number, clientesPorPagina: number, nombre:string){
    return this.http.get<{
        clientes:any;
        contador:number;
    }>("http://199.192.16.143:3000/api/admin/clientes/" + nombre + '/' + paginaActual + '/' + clientesPorPagina);   
  }

  validarPropiedad(propiedad: string) {
    return this.http.get<{
        error: boolean;
    }>("http://199.192.16.143:3000/api/propiedad/validar/" + propiedad);
  }

  getClienteVisto(cliente: string) {
    return this.http.get<{
        error: boolean;
    }>("http://199.192.16.143:3000/api/propiedad/vista/" + cliente);
  }

}

