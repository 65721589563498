import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { PropiedadService } from "../propiedades.service";
import { Propiedad } from "./propiedad.model";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-mis-propiedades",
  templateUrl: "./mis-propiedades.component.html",
  styleUrls: ["./mis-propiedades.component.css"]
})
export class MisPropiedadesComponent implements OnInit {
  propiedades: Propiedad[] = [];
  isLoading = false;
  userIsAuthenticated = false;
  userId: string;
  private authStatusSub: Subscription;

  constructor(
    public propiedadesService: PropiedadService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.propiedadesService.getPropiedades().subscribe(propiedadesData => {
        console.log(propiedadesData);
        this.propiedades = propiedadesData.propiedades;
        this.isLoading = false;
    });
    this.userId = this.authService.getUserId();

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }
}
