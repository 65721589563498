import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { Zona } from "../zona.model";
import { Agente } from "../agente.model";
import { AuthService } from "../../auth/auth.service";
import { ClienteService } from "../cliente.service";

@Component({
  selector: "app-asignar-cliente",
  templateUrl: "./asignar-cliente.component.html",
  styleUrls: ["./asignar-cliente.component.scss"]
})
export class AsignarClienteComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingZonas = false;
  isCiudadValida = false;
  empresaValida = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;
  zona2 : string;
  zona3 : string;
  zona4 : string;
  zona5 : string;
  zona6 : string;
  zona7 : string;
  zona8 : string;
  zona9 : string;
  zona10 : string;
   numeroDuplicado = false;
   cargandoNumero = false;
   agenteDuplicado = '';

  zonas: Zona[] = [];
  agentes: Agente[] = [];
  private authStatusSub: Subscription;
  private zonasSub: Subscription;

  constructor(
    private authService: AuthService,
    public clientesService: ClienteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoP: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoM: new FormControl(null, {}),
        celular: new FormControl(null, { validators: [Validators.required] }),
        correo: new FormControl(null, {}),
        agente: new FormControl(null, {validators: [Validators.required]}),
        descripcion: new FormControl(null, { validators: [Validators.required,Validators.minLength(3)] }),
        estado: new FormControl('venta', { validators: [Validators.required] }),
        tipo: new FormControl('casa', { validators: [Validators.required] }),
        precioMax: new FormControl(null, { validators: [Validators.required] }),
        ciudad: new FormControl(null, { validators: [Validators.required] }),
        fuente: new FormControl(1, { validators: [Validators.required] }),
        habitacionesMin: new FormControl(null, { }),
        banosMin: new FormControl(null, { }),
        garajesMin: new FormControl(null, { }),
        construidoMin: new FormControl(null, { }),
        terrenoMin: new FormControl(null, { }),
        zona1: new FormControl(null, { validators: [Validators.required] }),
        zona2: new FormControl(null, { }),
        zona3: new FormControl(null, { }),
        zona4: new FormControl(null, { }),
        zona5: new FormControl(null, { }),
        zona6: new FormControl(null, { }),
        zona7: new FormControl(null, { }),
        zona8: new FormControl(null, { }),
        zona9: new FormControl(null, { }),
        zona10: new FormControl(null, { }),
      });
      this.clientesService.getAgentes().subscribe(agentesData => {
        this.agentes = agentesData.agentes;
        this.isLoading = false;
      });
      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });  
  }

  
  onSaveCliente() {
    if (this.form.invalid) {
      return;
    }
    var correo = '';
    this.isLoading = true;
       if(this.form.value.zona2 == 0 || this.form.value.zona2 == null){
           this.zona2 = '0';
       }else{
           this.zona2 = this.form.value.zona2;
       }
       if(this.form.value.zona3 == 0 || this.form.value.zona3 == null){
           this.zona3 = '0';
       }else{
           this.zona3 = this.form.value.zona3;
       }
       if(this.form.value.zona4 == 0 || this.form.value.zona4 == null){
           this.zona4 = '0';
       }else{
           this.zona4 = this.form.value.zona4;
       }
       if(this.form.value.zona5 == 0 || this.form.value.zona5 == null){
           this.zona5 = '0';
       }else{
           this.zona5 = this.form.value.zona5;
       }
       if(this.form.value.zona6 == 0 || this.form.value.zona6 == null){
           this.zona6 = '0';
       }else{
           this.zona6 = this.form.value.zona6;
       }
       if(this.form.value.zona7 == 0 || this.form.value.zona7 == null){
           this.zona7 = '0';
       }else{
           this.zona7 = this.form.value.zona7;
       }
       if(this.form.value.zona8 == 0 || this.form.value.zona8 == null){
           this.zona8 = '0';
       }else{
           this.zona8 = this.form.value.zona8;
       }
       if(this.form.value.zona9 == 0 || this.form.value.zona9 == null){
           this.zona9 = '0';
       }else{
           this.zona9 = this.form.value.zona9;
       }
       if(this.form.value.zona10 == 0 || this.form.value.zona10 == null){
           this.zona10 = '0';
       }else{
           this.zona10 = this.form.value.zona10;
       }
	   var habitacionesMin = 0;
       if(this.form.value.habitacionesMin != '' || this.form.value.habitacionesMin != null){
           habitacionesMin = this.form.value.habitacionesMin;
       }
	   var banosMin = 0;
       if(this.form.value.banosMin != '' || this.form.value.banosMin != null){
           banosMin = this.form.value.banosMin;
       }
	   var garajesMin = 0;
       if(this.form.value.garajesMin != '' || this.form.value.garajesMin != null){
           garajesMin = this.form.value.garajesMin;
       }
	   var construidoMin = 0;
       if(this.form.value.construidoMin != '' || this.form.value.construidoMin != null){
           construidoMin = this.form.value.construidoMin;
       }
	   var terrenoMin = 0;
       if(this.form.value.terrenoMin != '' || this.form.value.terrenoMin != null){
           terrenoMin = this.form.value.terrenoMin;
       }
	   
      this.clientesService.asignarCliente(
       this.form.value.nombre,
       this.form.value.apellidoP,
       this.form.value.apellidoM,
       this.form.value.celular,
       correo,
	   this.form.value.agente,
       this.form.value.descripcion,
       this.form.value.fuente,
       this.form.value.estado,
       this.form.value.tipo,
       this.form.value.precioMax,
	   habitacionesMin,
	   banosMin,
	   garajesMin,
	   construidoMin,
	   terrenoMin,
       this.form.value.ciudad,
       this.form.value.zona1,
       this.zona2,
       this.zona3,
       this.zona4,
       this.zona5,
       this.zona6,
       this.zona7,
       this.zona8,
       this.zona9,
       this.zona10
      ).subscribe(clienteData => {
		  this.router.navigate(["/admin/clientes"]);
	  });
    this.form.reset();
  }
  
  
  onChangeNumero(deviceValue){
   this.cargandoNumero = true;
   this.numeroDuplicado = false;
   if(deviceValue.length>7){
       this.clientesService.getNombreAgente(deviceValue).subscribe(nombreData => {
           if(nombreData.nombre!=""){
               this.agenteDuplicado = "Numero ya registrado, contactar a: "+nombreData.nombre;
               this.numeroDuplicado = true;
           }else{
               this.agenteDuplicado = "";
           }
           this.cargandoNumero = false;
         });    
       }else{
           this.cargandoNumero = false;    
       }
   }

  onChangeCiudad(deviceValue){

      console.log(deviceValue);
    this.isLoadingZonas = true;
    this.isCiudadValida = false;
    this.clientesService.getZonas(deviceValue);
    this.zonasSub = this.clientesService
      .getZonaUpdateListener()
      .subscribe((zonaData: { zonas: Zona[] }) => {
          console.log(zonaData);
        this.isCiudadValida = true;
        this.zonas = zonaData.zonas;
        this.isLoadingZonas = false;
      });
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}

