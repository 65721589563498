import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

import { Producto } from "./producto.model";


@Injectable({ providedIn: "root" })
export class InventarioService {
  private productos: Producto[] = [];
  private contadorProductos: number;
  private productosUpdated = new Subject<{ productos: Producto[], contadorProductos: number}>();
  
  constructor(private http: HttpClient, private router: Router) {}

  

 
  addProducto(nombre: string | Blob, sku: string | Blob, descripcion: string| Blob, precio: string| Blob, cantidad: string| Blob, tipoProducto: string| Blob, image: File) {
      console.log('addEmpresa');
    const productoData = new FormData();
    
    productoData.append("nombre", nombre);
    productoData.append("sku", sku);
    productoData.append("descripcion", descripcion);
    productoData.append("precio", precio);
    productoData.append("cantidad", cantidad);
    productoData.append("tipoProducto", tipoProducto);
    productoData.append("image", image, "producto");
    this.http
      .post<{ elError: String; mensaje: string}>(
        "http://68.183.168.180:3000/api/productos/registro",
        productoData
      )
      .subscribe(responseData => {
        this.router.navigate(["/productos/lista"]);
      });
  }

  getProductosSimple(numeroPagina: number,productosPorPagina: number) {
    return this.http
      .get<{ error: boolean; productos: any; contador: number }>("http://68.183.168.180:3000/api/productos/lista/"+numeroPagina+"/"+productosPorPagina)
  }

  

  getProductosBusquedaSimple(nombre: string | Blob,numeroPagina: number,productosPorPagina: number) {
    return this.http
      .get<{ error: boolean; productos: any; contador: number }>("http://68.183.168.180:3000/api/productos/lista/"+nombre+"/"+numeroPagina+"/"+productosPorPagina)
  }

 


  getProducto(id: string) {
    return this.http.get<{
        error: boolean; productos: any
    }>("http://68.183.168.180:3000/api/producto/editar/" + id);
  }

  editProducto(nombre: string | Blob,descripcion: string| Blob, precio: string| Blob, id: string| Blob) {
  const productoData = new FormData();
  console.log('Ingresamos al servicio del producto');
  console.log(nombre);
  console.log(id);
  productoData.append("nombre", nombre);
  productoData.append("descripcion", descripcion);
  productoData.append("precio", precio);
  productoData.append("id", id);
  this.http
    .put<{ error: String; message: string}>(
      "http://68.183.168.180:3000/api/producto/editar",
      {
          "nombre": nombre,
          "descripcion": descripcion,
          "precio":precio,
          "id":id
      }
    )
    .subscribe(responseData => {
      this.router.navigate(["/productos/lista"]);
    });
}

    editCantidad(cantidad: string | Blob,tipo: string| Blob, productoId: string| Blob) {    
        this.http.post<{ error: String; message: string}>(
            "http://68.183.168.180:3000/api/producto/editar-cantidad",
            {
                "cantidad": cantidad,
                "tipo": tipo,
                "id":productoId
            }
          ).subscribe(responseData => {
            this.router.navigate(["/productos/lista"]);
          });
    }

}


