import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";
import { Chart } from 'chart.js';
import { ReportesService } from "../reportes.service";
import {DatePipe} from '@angular/common';

/*
import { Re } from "../reportes.service";
import { Cliente } from "../clienteAdmin.model";
*/
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-visitas-agentes-admin-30",
  templateUrl: "./visitas-agentes-30.component.html",
  styleUrls: ["./visitas-agentes-30.component.scss"]
})

export class VisitasAgentesAdmin30Component implements OnInit, AfterViewInit{

    title = 'Visitas realizadas por los agentes';
    BarChart=[];
    PieChart=[];
    isLoading = false;
    fecha1;
    fecha2;
    fecha3;
    fecha4;
    fecha5;
    fecha6;
	fecha7;
    fecha8;
	fecha9;
	fecha10;
	fecha11;
	fecha12;
	fecha13;
	fecha14;
	fecha15;
	fecha16;
	fecha17;
	fecha18;
	fecha19;
	fecha20;
	fecha21;
	fecha22;
	fecha23;
	fecha24;
	fecha25;
	fecha26;
	fecha27;
	fecha28;
	fecha29;
	fecha30;
	
    userId: string;
    userIsAuthenticated = false;
    visitas: any;
    agentes: any;
    nombreAgentes: any = [];
    porsentajeVisitas: any = [];
    private authStatusSub: Subscription;

    constructor(
        public reportesService: ReportesService,
        private authService: AuthService,
        private datePipe: DatePipe
      ) {}

    ngOnInit()
    {   
        //this.isLoading = true;
        this.fecha1 = new Date();
        this.fecha1 = this.datePipe.transform(new Date(),"dd-MM-yyyy");
        this.fecha2 = new Date();
        this.fecha2.setDate( this.fecha2.getDate() - 1 );    
        this.fecha2 = this.datePipe.transform(this.fecha2,"dd-MM-yyyy");
        this.fecha3 = new Date();
        this.fecha3.setDate( this.fecha3.getDate() - 2 );    
        this.fecha3 = this.datePipe.transform(this.fecha3,"dd-MM-yyyy");
        this.fecha4 = new Date();
        this.fecha4.setDate( this.fecha4.getDate() - 3 );    
        this.fecha4 = this.datePipe.transform(this.fecha4,"dd-MM-yyyy");
        this.fecha5 = new Date();
        this.fecha5.setDate( this.fecha5.getDate() - 4 );
        this.fecha5 = this.datePipe.transform(this.fecha5,"dd-MM-yyyy");
        this.fecha6 = new Date();
        this.fecha6.setDate( this.fecha6.getDate() - 5 );
        this.fecha6 = this.datePipe.transform(this.fecha6,"dd-MM-yyyy");
        this.fecha7 = new Date();
        this.fecha7.setDate( this.fecha7.getDate() - 6 );
        this.fecha7 = this.datePipe.transform(this.fecha7,"dd-MM-yyyy");
		this.fecha8 = new Date();
        this.fecha8.setDate( this.fecha8.getDate() - 7 );
        this.fecha8 = this.datePipe.transform(this.fecha8,"dd-MM-yyyy");
		this.fecha9 = new Date();
        this.fecha9.setDate( this.fecha9.getDate() - 8 );
        this.fecha9 = this.datePipe.transform(this.fecha9,"dd-MM-yyyy");
		this.fecha10 = new Date();
        this.fecha10.setDate( this.fecha10.getDate() - 9 );
        this.fecha10 = this.datePipe.transform(this.fecha10,"dd-MM-yyyy");
		this.fecha11 = new Date();
        this.fecha11.setDate( this.fecha11.getDate() - 10 );
        this.fecha11 = this.datePipe.transform(this.fecha11,"dd-MM-yyyy");
		this.fecha12 = new Date();
        this.fecha12.setDate( this.fecha12.getDate() - 11 );
        this.fecha12 = this.datePipe.transform(this.fecha12,"dd-MM-yyyy");
		this.fecha13 = new Date();
        this.fecha13.setDate( this.fecha13.getDate() - 12 );
        this.fecha13 = this.datePipe.transform(this.fecha13,"dd-MM-yyyy");
		this.fecha14 = new Date();
        this.fecha14.setDate( this.fecha14.getDate() - 13 );
        this.fecha14 = this.datePipe.transform(this.fecha14,"dd-MM-yyyy");
		this.fecha15 = new Date();
        this.fecha15.setDate( this.fecha15.getDate() - 14 );
        this.fecha15 = this.datePipe.transform(this.fecha15,"dd-MM-yyyy");
		this.fecha16 = new Date();
        this.fecha16.setDate( this.fecha16.getDate() - 15 );
        this.fecha16 = this.datePipe.transform(this.fecha16,"dd-MM-yyyy");
		this.fecha17 = new Date();
        this.fecha17.setDate( this.fecha17.getDate() - 16 );
        this.fecha17 = this.datePipe.transform(this.fecha17,"dd-MM-yyyy");
		this.fecha18 = new Date();
        this.fecha18.setDate( this.fecha18.getDate() - 17 );
        this.fecha18 = this.datePipe.transform(this.fecha18,"dd-MM-yyyy");
		this.fecha19 = new Date();
        this.fecha19.setDate( this.fecha19.getDate() - 18 );
        this.fecha19 = this.datePipe.transform(this.fecha19,"dd-MM-yyyy");
		this.fecha20 = new Date();
        this.fecha20.setDate( this.fecha20.getDate() - 19 );
        this.fecha20 = this.datePipe.transform(this.fecha20,"dd-MM-yyyy");
		this.fecha21 = new Date();
        this.fecha21.setDate( this.fecha21.getDate() - 20 );
        this.fecha21 = this.datePipe.transform(this.fecha21,"dd-MM-yyyy");
		this.fecha22 = new Date();
        this.fecha22.setDate( this.fecha22.getDate() - 21 );
        this.fecha22 = this.datePipe.transform(this.fecha22,"dd-MM-yyyy");
		this.fecha23 = new Date();
        this.fecha23.setDate( this.fecha23.getDate() - 22 );
        this.fecha23 = this.datePipe.transform(this.fecha23,"dd-MM-yyyy");
		this.fecha24 = new Date();
        this.fecha24.setDate( this.fecha24.getDate() - 23 );
        this.fecha24 = this.datePipe.transform(this.fecha24,"dd-MM-yyyy");
		this.fecha25 = new Date();
        this.fecha25.setDate( this.fecha25.getDate() - 24 );
        this.fecha25 = this.datePipe.transform(this.fecha25,"dd-MM-yyyy");
		this.fecha26 = new Date();
        this.fecha26.setDate( this.fecha26.getDate() - 25 );
        this.fecha26 = this.datePipe.transform(this.fecha26,"dd-MM-yyyy");
		this.fecha27 = new Date();
        this.fecha27.setDate( this.fecha27.getDate() - 26 );
        this.fecha27 = this.datePipe.transform(this.fecha27,"dd-MM-yyyy");
		this.fecha28 = new Date();
        this.fecha28.setDate( this.fecha28.getDate() - 27 );
        this.fecha28 = this.datePipe.transform(this.fecha28,"dd-MM-yyyy");
		this.fecha29 = new Date();
        this.fecha29.setDate( this.fecha29.getDate() - 28 );
        this.fecha29 = this.datePipe.transform(this.fecha29,"dd-MM-yyyy");
		this.fecha30 = new Date();
        this.fecha30.setDate( this.fecha30.getDate() - 29 );
        this.fecha30 = this.datePipe.transform(this.fecha30,"dd-MM-yyyy");
            
        const controlVisitas = this.getVisitas().then(()=>{
        


        });

    }

    ngAfterViewInit() {

    }

    async getVisitas(){
        this.reportesService.getVisitasAgentesSimple30().subscribe(visitasData => {        
            this.visitas = visitasData.visitas;
            this.agentes = visitasData.agentes;
            var fechaInicial = new Date();
            

            for(var i=0;i<this.agentes.length;i++){
                this.nombreAgentes.push(this.agentes[i]['agente']);
                this.porsentajeVisitas.push(this.agentes[i]['numero']);
            }
            console.log(this.nombreAgentes);
            console.log(this.porsentajeVisitas);

            // Bar chart:
        this.BarChart = new Chart('barChart', {
            type: 'bar',
            data: {
                labels: [this.fecha30, this.fecha29, this.fecha28, this.fecha27, this.fecha26, this.fecha25,this.fecha24,this.fecha23,this.fecha22,this.fecha21,this.fecha20,this.fecha19,this.fecha18,this.fecha17,this.fecha16,this.fecha15,this.fecha14,this.fecha13,this.fecha12,this.fecha11,this.fecha10,this.fecha9,this.fecha8,this.fecha7,this.fecha6,this.fecha5,this.fecha4,this.fecha3,this.fecha2,this.fecha1],
            datasets: [{
                label: '# Visitas',
                data: [this.visitas[0][30],this.visitas[0][29],this.visitas[0][28],this.visitas[0][27],this.visitas[0][26],this.visitas[0][25],this.visitas[0][24],this.visitas[0][23],this.visitas[0][22],this.visitas[0][21],this.visitas[0][20],this.visitas[0][19],this.visitas[0][18],this.visitas[0][17],this.visitas[0][16],this.visitas[0][15],this.visitas[0][14],this.visitas[0][13],this.visitas[0][12],this.visitas[0][11],this.visitas[0][10],this.visitas[0][9],this.visitas[0][8],this.visitas[0][7],this.visitas[0][6],this.visitas[0][5],this.visitas[0][4],this.visitas[0][3],this.visitas[0][2],this.visitas[0][1],this.visitas[0][0]],
                borderWidth: 1
            }]
            }, 
            options: {
            title:{
                text:"Bar Chart",
                display:false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
            }
            });
    
            // pie chart:
            this.PieChart = new Chart('pieChart', {
            type: 'pie',
            data: {
            labels: this.nombreAgentes,
            datasets: [{
                label: '% de visitas',
                data: this.porsentajeVisitas,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
					'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
					'rgba(255, 99, 132, 0.7)',
                    'rgba(54, 162, 235, 0.7)',
                    'rgba(255, 206, 86, 0.7)',
                    'rgba(75, 192, 192, 0.7)',
                    'rgba(153, 102, 255, 0.7)',
                    'rgba(255, 159, 64, 0.7)',
					'rgba(255, 99, 132, 0.9)',
                    'rgba(54, 162, 235, 0.9)',
                    'rgba(255, 206, 86, 0.9)',
                    'rgba(75, 192, 192, 0.9)',
                    'rgba(153, 102, 255, 0.9)',
                    'rgba(255, 159, 64, 0.9)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
            }, 
            options: {
            title:{
                text:"Bar Chart",
                display:false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
            }
            });
            this.isLoading = false;



            return 0;
        });
    }
}
