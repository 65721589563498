import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { ClienteService } from "../cliente.service";

@Component({
  selector: "app-modificar-cliente",
  templateUrl: "./modificar-cliente.component.html",
  styleUrls: ["./modificar-cliente.component.scss"]
})
export class ModificarClienteComponent implements OnInit {
  isLoading = false;
  isLoadingZonas = false;
  isCiudadValida = false;
  empresaValida = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;

  private authStatusSub: Subscription;


  constructor(
    private authService: AuthService,
    public clientesService: ClienteService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.form = new FormGroup({
        nombre: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoP: new FormControl(null, { validators: [Validators.required, Validators.minLength(3)] }),
        apellidoM: new FormControl(null, {}),
        celular1: new FormControl(null, { validators: [Validators.required] }),
        celular2: new FormControl(null, {}),
        telefono1: new FormControl(null, {}),
        telefono2: new FormControl(null, {}),
        correo: new FormControl(null, {}),
        nacionalidad: new FormControl(null, {}),
        ci: new FormControl(null, {}),
        genero: new FormControl(null, {}),
        nacimiento: new FormControl(null, {}),
      });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        var temporalVistas
        if (paramMap.has("clienteId")) {
                this.clientesService.getCliente(
                paramMap.get('clienteId'),
               ).subscribe(cliente => {
                   console.log(cliente);
                    this.form.controls['nombre'].setValue(cliente['cliente'][0]['nombre']);
                    this.form.controls['apellidoP'].setValue(cliente['cliente'][0]['apellido_paterno']);
                    this.form.controls['apellidoM'].setValue(cliente['cliente'][0]['apellido_materno']);
                    this.form.controls['celular1'].setValue(cliente['cliente'][0]['celular1']);
                    this.form.controls['celular2'].setValue(cliente['cliente'][0]['celular2']);
                    this.form.controls['telefono1'].setValue(cliente['cliente'][0]['telefono1']);
                    this.form.controls['telefono2'].setValue(cliente['cliente'][0]['telefono2']);
                    this.form.controls['correo'].setValue(cliente['cliente'][0]['correo']);
                    this.form.controls['nacionalidad'].setValue(cliente['cliente'][0]['nacionalidad']);
                    this.form.controls['ci'].setValue(cliente['cliente'][0]['ci']);
                    this.form.controls['genero'].setValue(cliente['cliente'][0]['genero']);
                    var fecha = cliente['cliente'][0]['nacimiento'].substring(0, 10);
                    console.log(this.form.value.nacimiento);
                    this.form.controls['nacimiento'].setValue(fecha);
              });
              this.isLoading = false;
        }
    });
    
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
      
  }

  
  onModificarCliente(){
      console.log(this.form.value.nacimiento);
      if (this.form.invalid) {
        return;
      }
      var correo = '';
      var apellidoM = '';
      
      this.isLoading = true;
      if(this.form.value.correo != '' || this.form.value.correo != null){
          correo = this.form.value.correo;
      }
      if(this.form.value.apellidoM != '' || this.form.value.apellidoM != null){
        apellidoM = this.form.value.apellidoM;
      }
      var celular2 = '';
      if(this.form.value.celular2 != '' || this.form.value.celular2 != null){
        celular2 = this.form.value.celular2;
        }
        var telefono1 = '';
      if(this.form.value.telefono1 != '' || this.form.value.telefono1 != null){
        telefono1 = this.form.value.telefono1;
        }
        var telefono2 = '';
      if(this.form.value.telefono2 != '' || this.form.value.telefono2 != null){
        telefono2 = this.form.value.telefono2;
        }
        var telefono2 = '';
      if(this.form.value.telefono2 != '' || this.form.value.telefono2 != null){
        telefono2 = this.form.value.telefono2;
        }
        var nacionalidad = '';
      if(this.form.value.nacionalidad != '' || this.form.value.nacionalidad != null){
        nacionalidad = this.form.value.nacionalidad;
        }
        var ci = '';
      if(this.form.value.ci != '' || this.form.value.ci != null){
        ci = this.form.value.ci;
        }
        var genero = '';
      if(this.form.value.genero != '' || this.form.value.genero != null){
        genero = this.form.value.genero;
        }
        var nacimiento = '';
      if(this.form.value.nacimiento != '' || this.form.value.nacimiento != null){
        nacimiento = this.form.value.nacimiento;
        }
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            var temporalVistas
            if (paramMap.has("clienteId")) {
                    this.clientesService.modificarCliente(
                    paramMap.get('clienteId'),
                    this.form.value.nombre,
                    this.form.value.apellidoP,
                    apellidoM,
                    this.form.value.celular1,
                    celular2,
                    telefono1,
                    telefono2,
                    correo,
                    nacionalidad,
                    ci,
                    genero,
                    nacimiento,
                   ).subscribe(modificado => {
                        this.router.navigate(["/agente/clientes"]);
                  });
                   this.isLoading = false;
            }
        });
  }
  
}

