import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from "rxjs";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from "../auth/auth.service";

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css']
})
export class MenuPrincipalComponent {
    userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  private gradoListenerSubs: Subscription;
  grado: number;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,private authService: AuthService) {}

  ngOnInit() {
    
    console.log("mostrar grado Primer Ingreso");
    console.log(this.grado);


    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.grado =  parseInt(localStorage.getItem("grado"),10);
      });


      this.grado = this.authService.getGrado();
      this.gradoListenerSubs = this.authService
        .getGradoListener()
        .subscribe(elGrado => {
          this.grado =  elGrado;
        });  
  }

  /*
  ngOnChanges(changes: SimpleChanges) {
    
    changes = parseInt(localStorage.getItem("grado"),10);
    console.log("mostrar grado");
    console.log(this.grado);
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.grado =  parseInt(localStorage.getItem("grado"),10);
      });
  }
  */
  

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.gradoListenerSubs.unsubscribe();
  }

}


