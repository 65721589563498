import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Producto } from "../producto.model";

import { InventarioService } from "../inventario.service";
import { AuthService } from "../../auth/auth.service";
import { mimeType } from "./mime-type.validator";

@Component({
  selector: "app-agregar-producto",
  templateUrl: "./agregar-producto.component.html",
  styleUrls: ["./agregar-producto.component.css"]
})
export class AgregarProductoComponent implements OnInit, OnDestroy {
  isLoading = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;
  imagePreview: string | ArrayBuffer;
    producto: Producto;
  private authStatusSub: Subscription;

  constructor(
    private authService: AuthService,
    public inventarioService: InventarioService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
        nombre: new FormControl(null, {
          validators: [Validators.required, Validators.minLength(3)]
        }),
        sku: new FormControl(null, { validators: [Validators.minLength(2)] }),
        descripcion: new FormControl(null, { validators: [Validators.required] }),
        precio: new FormControl(null, { validators: [Validators.required] }),
        cantidad: new FormControl(null, { validators: [Validators.required] }),
        tipoProducto: new FormControl(null, { validators: [Validators.required] }),
        image: new FormControl(null, {
          validators: [Validators.required],
          asyncValidators: [mimeType]
        })
      });


      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
        
      });
  }


  onImagePicked(event: Event) {
    this.isLoading = true;
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ image: file });
    this.form.get("image").updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
        this.imagePreview = reader.result;
    };
    this.isLoading = false;
    reader.readAsDataURL(file);
  }

  
  onSaveProducto() {
    console.log('Ingreso Producto');
    if (this.form.invalid) {
      console.log('Ingreso error Producto 3');  
      return;
    }
    console.log('Ingreso Producto 2');
    this.isLoading = true;
      this.inventarioService.addProducto(
        this.form.value.nombre,
        this.form.value.sku,
        this.form.value.descripcion,
        this.form.value.precio,
        this.form.value.cantidad,
        this.form.value.tipoProducto,
        this.form.value.image
      );
      console.log('Ingreso Producto 3');  
    this.form.reset();
  }
  

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}

