import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";


@Injectable({ providedIn: "root" })
export class ReportesService {
    constructor(private http: HttpClient, private router: Router) {}

    getVisitasAgentesSimple(){
        return this.http.get<{
            error:boolean;
            visitas:any;
            agentes:any;
        }>("http://199.192.16.143:3000/api/reportes/vistas/simple");   
    }
	
	getVisitasAgentesSimple30(){
        return this.http.get<{
            error:boolean;
            visitas:any;
            agentes:any;
        }>("http://199.192.16.143:3000/api/reportes/vistas/simple/30");   
    }
	
	getAporteFirmaAnual(){
        return this.http.get<{
            error:boolean;
            ventas:any;
        }>("http://199.192.16.143:3000/api/reportes/aporte/anual");   
    }
       
	getHistorialVisitasPersonalizado(paginaActual: number, visitasPorPagina: number, codigo:number,fechaInicio: string, fechaFin: string){
        return this.http.get<{
            visitas:any;
            contador:number;
        }>("http://199.192.16.143:3000/api/reportes/visitas/agentes/personalizado/" + codigo + '/' + fechaInicio + '/'  + fechaFin + '/' + paginaActual + '/' + visitasPorPagina);   
    }

	getAgentes(){
		return this.http
		.get<{ agentes: any}>(
			"http://199.192.16.143:3000/api/admin/agentes",
		{}
		)
	}
}
