import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {DatePipe} from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatInputModule,MatCardModule, MatIconModule, MatListModule } from '@angular/material';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {MatGridListModule} from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatRadioModule} from '@angular/material/radio';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PostCreateComponent } from './post/post-create/post-create.component';
import { InicioComponent } from './inicio/inicio.component';
import { MenuPrincipalComponent } from './menu-principal/menu-principal.component';
import { ListaEmpresasComponent } from './empresas/lista-empresas/lista-empresas.component';
import { PostListComponent } from './post/post-list/post-list.component';
import { LoginComponent } from './auth/login/login.component';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatProgressSpinnerModule}  from '@angular/material/progress-spinner';
import { MenuPruebaComponent } from './menu-prueba/menu-prueba.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AuthInterceptor } from "./auth/auth-interceptor";
import { RegistroEmpresaComponent } from './empresas/registro-empresa/registro-empresa.component';
import { RegistroEmpresaUsuarioComponent } from './empresas/registro-usuario/registro-usuario.component';
import { ListaUsuariosComponent } from './empresas/lista-usuarios/lista-usuarios.component';
import { RegistroEmpresaSucursalComponent } from './empresas/registro-sucursal/registro-sucursal.component';
import { AgregarProductoComponent } from './inventario/agregar-producto/agregar-producto.component';
import { ProductosComponent } from './inventario/productos/productos.component';
import { EditarProductoComponent } from './inventario/editar-producto/editar-producto.component';
import { ModificarCantidadComponent } from './inventario/modificar-cantidad/modificar-cantidad.component';
import { ListaSucursalesComponent } from './gerente/lista-sucursales/lista-sucursales.component';
import { AgregarSucursalComponent } from './gerente/agregar-sucursal/agregar-sucursal.component';
import { AgregarUsuarioComponent } from './gerente/agregar-usuario/agregar-usuario.component';
import { ListaUsuariosSucursalComponent } from './gerente/lista-usuarios-sucursal/lista-usuarios-sucursal.component';
import { AgregarClienteComponent } from './admin-cliente/agregar-cliente/agregar-cliente.component';
import { PropiedadesRelacionadasComponent } from './admin-cliente/propiedades_relacionadas/propiedades_relacionadas.component';
import { ListaClientesComponent } from './admin-cliente/lista-clientes/lista-clientes.component';
import { ListaRequerimientosComponent } from './admin-cliente/lista-requerimientos/lista-requerimientos.component';
import { ModificarClienteComponent } from './admin-cliente/modificar-cliente/modificar-cliente.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgregarRequerimientoComponent } from './admin-cliente/agregar-requerimiento/agregar-requerimiento.component';
import { AsignarClienteComponent } from './admin-cliente/asignar-cliente/asignar-cliente.component';
import { ListaClientesAdminComponent } from './admin-cliente/lista-clientes-admin/lista-clientes-admin.component';
import { SolicitarVisitaComponent } from './admin-visitas/solicitar-visita/solicitar-visita.component';
import { VisitasSolicitadasComponent } from './admin-visitas/visitas-solicitadas/visitas-solicitadas.component';
import { VisitasAprobadasComponent } from './admin-visitas/visitas-aprobadas/visitas-aprobadas.component';
import { RegistrarVisitaProgramadaComponent } from './admin-visitas/registrar-visita-programada/registrar-visita-programada.component';
import { HistorialVisitasComponent } from './admin-visitas/historial-visitas/historial-visitas.component';
import { DetalleVisitaComponent } from './admin-visitas/detalle-visita/detalle-visita.component';
import { RegistrarVisitaInstantaneaComponent } from './admin-visitas/registrar-visita-instantanea/registrar-visita-instantanea.component';
import { MisPropiedadesComponent } from './admin-propiedad/mis-propiedades/mis-propiedades.component';
import { AsignarClienteCodigoComponent } from './admin-cliente/asignar-cliente-codigo/asignar-cliente-codigo.component';
import { ModificarZonasComponent } from './admin-cliente/modificar-zonas/modificar-zonas.component';
import { PropiedadRequerimientoComponent } from './admin-propiedad/propiedad-requerimiento/propiedad-requerimiento.component';
import { VisitassAgentesAdminComponent } from './admin-reportes/visitas-agentes/visitas-agentes.component';
import { AporteFirmaAnualComponent } from './admin-reportes/aporte-firma-anual/aporte-firma-anual.component';
import { HistorialPorPropiedadComponent } from './admin-visitas/historial-por-propiedad/historial-por-propiedad.component';
import {  RegistroPropiedadCaracteristicasComponent } from './admin-propiedad/registro-propiedad-caracteristicas/registro-propiedad-caracteristicas.component';
import { VisitasAgentesAdmin30Component } from './admin-reportes/visitas-agentes-30/visitas-agentes-30.component';
import { VisitasPersonalizadaComponent } from './admin-reportes/visitas-personalizada/visitas-personalizada.component';
//import { ErrorInterceptor } from "./error-interceptor";

@NgModule({
  declarations: [
    AppComponent,
    PostCreateComponent,
    PostListComponent,
    LoginComponent,
    HeaderComponent,
    MenuPruebaComponent,
    MenuPrincipalComponent,
    InicioComponent,
    ListaEmpresasComponent,
    RegistroEmpresaComponent,
    RegistroEmpresaUsuarioComponent,
    ListaUsuariosComponent,
    RegistroEmpresaSucursalComponent,
    AgregarProductoComponent,
    ProductosComponent,
    EditarProductoComponent,
    ModificarCantidadComponent,
    ListaSucursalesComponent,
    AgregarSucursalComponent,
    AgregarUsuarioComponent,
    ListaUsuariosSucursalComponent,
    AgregarClienteComponent,
    PropiedadesRelacionadasComponent,
    ListaClientesComponent,
    ListaRequerimientosComponent,
    ModificarClienteComponent,
    AgregarRequerimientoComponent,
    AsignarClienteComponent,
    ListaClientesAdminComponent,
    SolicitarVisitaComponent,
    VisitasSolicitadasComponent,
    VisitasAprobadasComponent,
    RegistrarVisitaProgramadaComponent,
    HistorialVisitasComponent,
    DetalleVisitaComponent,
    RegistrarVisitaInstantaneaComponent,
    MisPropiedadesComponent,
    AsignarClienteCodigoComponent,
    ModificarZonasComponent,
    PropiedadRequerimientoComponent,
    VisitassAgentesAdminComponent,
	AporteFirmaAnualComponent,
	HistorialPorPropiedadComponent,
	RegistroPropiedadCaracteristicasComponent,
	VisitasAgentesAdmin30Component,
	VisitasPersonalizadaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatExpansionModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatGridListModule,
    LayoutModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatGridListModule,
    FlexLayoutModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule
  ],
  providers: [
      {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
      DatePipe
    //{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
],
  bootstrap: [AppComponent]
})
export class AppModule { }
