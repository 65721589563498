import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { Sucursal } from "../sucursales.model";
import { GerenteService } from "../gerente.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-lista-sucursales",
  templateUrl: "./lista-sucursales.component.html",
  styleUrls: ["./lista-sucursales.component.css"]
})
export class ListaSucursalesComponent implements OnInit, OnDestroy {
  sucursales: Sucursal[] = [];
  isLoading = false;
  
  userIsAuthenticated = false;
  userId: string;
  private sucursalesSub: Subscription;
  private authStatusSub: Subscription;

  constructor(
    public gerenteService: GerenteService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.gerenteService.getSucursales();
    this.userId = this.authService.getUserId();
    this.sucursalesSub = this.gerenteService
      .getSucursalUpdateListener()
      .subscribe((sucursalesData: { sucursales: Sucursal[] }) => {
      
        this.isLoading = false;
        this.sucursales = sucursalesData.sucursales;
      
      });
      
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  ngOnDestroy() {
    this.sucursalesSub.unsubscribe();
    this.authStatusSub.unsubscribe();
  }

}
