import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";

import { ClienteService } from "../cliente.service";
import { Cliente } from "../cliente.model";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-lista-clientes",
  templateUrl: "./lista-clientes.component.html",
  styleUrls: ["./lista-clientes.component.scss"]
})
export class ListaClientesComponent implements OnInit {
  clientes: Cliente[] = [];
  isLoading = false;
  totalClientes = 0;
  clientesPerPage = 10;
  currentPage = 1;
  form: FormGroup;
  pageSizeOptions = [10, 15, 20, 25];
  userIsAuthenticated = false;
  userId: string;
  private authStatusSub: Subscription;

  constructor(
    public clientesService: ClienteService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.clientesService.getListaClientes( this.currentPage -1, this.clientesPerPage).subscribe(clientesData => {
        console.log(clientesData);
        this.clientes = clientesData.clientes;
        console.log(this.clientes);
        this.totalClientes = clientesData.contador;
        this.isLoading = false;
    });
    this.userId = this.authService.getUserId();
      
    this.form = new FormGroup({
        nombre: new FormControl(null,),
      });

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  onChangedPage(pageData: PageEvent) {
    this.isLoading = true;  
    this.currentPage = pageData.pageIndex + 1;
    this.clientesPerPage = pageData.pageSize;
    console.log(this.clientesPerPage);
    console.log(this.currentPage);
    console.log(this.form.value.nombre);
    if(this.form.value.nombre == null){
        this.clientesService.getListaClientes( this.currentPage -1, this.clientesPerPage).subscribe(clientesData => {
            this.clientes = clientesData.clientes;
            this.totalClientes = clientesData.contador;
            this.isLoading = false;
        });
    }else{
        this.clientesService.getListaFiltroClientes( this.currentPage -1, this.clientesPerPage,this.form.value.nombre).subscribe(clientesData => {
            this.clientes = clientesData.clientes;
            this.totalClientes = clientesData.contador;
            this.isLoading = false;
        });
    }
  }

  onEditBusqueda() {
    this.isLoading = true;  
    this.currentPage = 1;
    if(this.form.value.nombre == null){
        this.clientesService.getListaClientes( this.currentPage -1, this.clientesPerPage).subscribe(clientesData => {
            this.clientes = clientesData.clientes;
            this.totalClientes = clientesData.contador;
            this.isLoading = false;
        });
    }else{
        this.clientesService.getListaFiltroClientes( this.currentPage -1, this.clientesPerPage,this.form.value.nombre).subscribe(clientesData => {
            this.clientes = clientesData.clientes;
            this.totalClientes = clientesData.contador;
            this.isLoading = false;
        });
    }
  }
}
