import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material";
import { Subscription } from "rxjs";
import { Chart } from 'chart.js';
import { ReportesService } from "../reportes.service";
import {DatePipe} from '@angular/common';

import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-aporte-firma-anual",
  templateUrl: "./aporte-firma-anual.component.html",
  styleUrls: ["./aporte-firma-anual.component.css"]
})

export class AporteFirmaAnualComponent implements OnInit{
	ventas: any;
	isLoading = false;
	totalVentas = 0;
	nombreAgentes: any = [];
	porsentajeAporte: any = [];
	PieChart=[];
	BarChart=[];
/*
    title = 'Visitas realizadas por los agentes';
    BarChart=[];
    PieChart=[];
    isLoading = false;
    fecha1;
    fecha2;
    fecha3;
    fecha4;
    fecha5;
    fecha6;
    fecha7;
    userId: string;
    userIsAuthenticated = false;
    vendedores: any;
    consignadores: any;
    nombreAgentes: any = [];
    porsentajeVisitas: any = [];
    private authStatusSub: Subscription;
*/
    constructor(
        public reportesService: ReportesService,
        private authService: AuthService,
        private datePipe: DatePipe
      ) {}

    ngOnInit()
    {   
        this.isLoading = true;
        this.reportesService.getAporteFirmaAnual().subscribe(aportesData => {
			this.ventas = aportesData['ventas'][0];
			console.log(this.ventas.length);
			console.log(this.ventas);
			for(var i = 0;i<this.ventas.length;i++){
				this.totalVentas+=this.ventas[i]['total'];
			}
			console.log(this.totalVentas);
			this.isLoading = false;
			
			
			for(var i=0;i<this.ventas.length;i++){
                this.nombreAgentes.push(this.ventas[i]['vendedor']);
                this.porsentajeAporte.push(this.ventas[i]['total']/this.totalVentas*100);
            }
            
        
            // pie chart:
            this.PieChart = new Chart('pieChart', {
            type: 'pie',
            data: {
            labels: this.nombreAgentes,
            datasets: [{
                label: '% de Ingresos',
                data: this.porsentajeAporte,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
					'rgba(250,232,134, 0.2)',
                    'rgba(247,56,27, 0.2)',
                    'rgba(113,121,64, 0.2)',
                    'rgba(169,35,224, 0.2)',
                    'rgba(160,54,250, 0.2)',
					'rgba(84,177,142, 0.2)',
                    'rgba(197,150,156, 0.2)',
                    'rgba(245,227,36, 0.2)',
                    'rgba(227,153,38, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
					'rgba(142,183,162, 0.2)',
                    'rgba(248,63,152, 0.2)',
                    'rgba(31,47,9, 0.2)',
                    'rgba(4,136,243, 0.2)',
                    'rgba(167,73,88, 0.2)',
					'rgba(160,54,250, 0.5)',
					'rgba(84,177,142, 0.5)',
                    'rgba(197,150,156, 0.5)',
                    'rgba(245,227,36, 0.5)',
                    'rgba(227,153,38, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
					'rgba(142,183,162, 0.5)',
                    'rgba(248,63,152, 0.5)',
                    'rgba(31,47,9, 0.5)',
					'rgba(4,136,243, 0.8)',
                    'rgba(167,73,88, 0.8)',
					'rgba(160,54,250, 0.8)',
					'rgba(84,177,142, 0.8)',
                    'rgba(197,150,156, 0.8)',
                    'rgba(245,227,36, 0.8)',
                    'rgba(227,153,38, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
					'rgba(142,183,162, 0.8)',
                    'rgba(248,63,152, 0.8)',
                    'rgba(31,47,9, 0.8)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
					'rgba(84,177,142, 0.2)',
                    'rgba(197,150,156, 0.2)',
                    'rgba(245,227,36, 0.2)',
                    'rgba(227,153,38, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
					'rgba(142,183,162, 0.2)',
                    'rgba(248,63,152, 0.2)',
                    'rgba(31,47,9, 0.2)',
                    'rgba(4,136,243, 0.2)',
                    'rgba(167,73,88, 0.2)'
                ],
                borderWidth: 1
            }]
            }, 
            options: {
            title:{
                text:"Porsentajes",
                display:false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
            }
            });
			
			
			
			// Bar chart:
			this.BarChart = new Chart('barChart', {
            type: 'bar',
            data: {
                labels: this.nombreAgentes,
            datasets: [{
                label: '# Porsentaje',
                data: this.porsentajeAporte,
				backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
					'rgba(250,232,134, 0.2)',
                    'rgba(247,56,27, 0.2)',
                    'rgba(113,121,64, 0.2)',
                    'rgba(169,35,224, 0.2)',
                    'rgba(160,54,250, 0.2)',
					'rgba(84,177,142, 0.2)',
                    'rgba(197,150,156, 0.2)',
                    'rgba(245,227,36, 0.2)',
                    'rgba(227,153,38, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
					'rgba(142,183,162, 0.2)',
                    'rgba(248,63,152, 0.2)',
                    'rgba(31,47,9, 0.2)',
                    'rgba(4,136,243, 0.2)',
                    'rgba(167,73,88, 0.2)',
					'rgba(160,54,250, 0.5)',
					'rgba(84,177,142, 0.5)',
                    'rgba(197,150,156, 0.5)',
                    'rgba(245,227,36, 0.5)',
                    'rgba(227,153,38, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
					'rgba(142,183,162, 0.5)',
                    'rgba(248,63,152, 0.5)',
                    'rgba(31,47,9, 0.5)',
					'rgba(4,136,243, 0.8)',
                    'rgba(167,73,88, 0.8)',
					'rgba(160,54,250, 0.8)',
					'rgba(84,177,142, 0.8)',
                    'rgba(197,150,156, 0.8)',
                    'rgba(245,227,36, 0.8)',
                    'rgba(227,153,38, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
					'rgba(142,183,162, 0.8)',
                    'rgba(248,63,152, 0.8)',
                    'rgba(31,47,9, 0.8)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
					'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
					'rgba(84,177,142, 0.2)',
                    'rgba(197,150,156, 0.2)',
                    'rgba(245,227,36, 0.2)',
                    'rgba(227,153,38, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
					'rgba(142,183,162, 0.2)',
                    'rgba(248,63,152, 0.2)',
                    'rgba(31,47,9, 0.2)',
                    'rgba(4,136,243, 0.2)',
                    'rgba(167,73,88, 0.2)'
                ],
                borderWidth: 1
            }]
            }, 
            options: {
            title:{
                text:"Porsentaje",
                display:false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
            }
            });
			
			
			
		});

    }

}
