import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { ActivatedRoute, ParamMap } from "@angular/router";

import { Usuario } from "../usuario.model";
import { GerenteService } from "../gerente.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-lista-usuarios-sucursal",
  templateUrl: "./lista-usuarios-sucursal.component.html",
  styleUrls: ["./lista-usuarios-sucursal.component.css"]
})
export class ListaUsuariosSucursalComponent implements OnInit, OnDestroy {
  usuarios: Usuario[] = [];
  isLoading = false;
  isError = false;
  
  userIsAuthenticated = false;
  userId: string;
  private usuariosSub: Subscription;
  private authStatusSub: Subscription;

  constructor(
    public gerenteService: GerenteService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("sucursalId")) {
            console.log('lista de uusarios');
            console.log(paramMap.get("sucursalId"));
            this.gerenteService.getUsuarios(paramMap.get("sucursalId"));
            this.userId = this.authService.getUserId();
            this.usuariosSub = this.gerenteService
            .getUsuariosUpdateListener()
            .subscribe((usuariosData: { usuarios: Usuario[] }) => {
                this.isLoading = false;
                this.usuarios = usuariosData.usuarios;
            });
            this.isLoading = false;
        }else{
            this.isError = true;
        }
      });
    
    
      
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });
  }

  ngOnDestroy() {
    this.usuariosSub.unsubscribe();
    this.authStatusSub.unsubscribe();
  }

}
