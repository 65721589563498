import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { Empresa } from "./empresa.model";
import { EmpresasService } from "../empresas.service";
import { AuthService } from "../../auth/auth.service";
import { mimeType } from "./mime-type.validator";

@Component({
  selector: "app-registro-empresa",
  templateUrl: "./registro-empresa.component.html",
  styleUrls: ["./registro-empresa.component.css"]
})
export class RegistroEmpresaComponent implements OnInit, OnDestroy {
  isLoading = false;
  userIsAuthenticated = false;
  userId: string;
  form: FormGroup;
  imagePreview: string | ArrayBuffer;
    empresa: Empresa;
    private mode = "create";
    private empresaId: string;
  private authStatusSub: Subscription;

  constructor(
    private authService: AuthService,
    public empresasService: EmpresasService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
        nombre: new FormControl(null, {
          validators: [Validators.required, Validators.minLength(3)]
        }),
        nit: new FormControl(null, { validators: [Validators.required, Validators.minLength(6)] }),
        direccion: new FormControl(null, { validators: [Validators.required, Validators.minLength(6)] }),
        tipoFactura: new FormControl(null, { validators: [Validators.required] }),
        tipoEmpresa: new FormControl(null, { validators: [Validators.required] }),
        ciudad: new FormControl(null, { validators: [Validators.required] }),
        image: new FormControl(null, {
          validators: [Validators.required],
          asyncValidators: [mimeType]
        })
      });


      
    this.userId = this.authService.getUserId();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
          console.log(this.isLoading);
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
        
      });
  }


  onImagePicked(event: Event) {
    this.isLoading = true;
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ image: file });
    this.form.get("image").updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
        this.imagePreview = reader.result;
    };
    this.isLoading = false;
    reader.readAsDataURL(file);
  }

  
  onSaveEmpresa() {
      console.log('onSaveEmpresa');
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
      this.empresasService.addEmpresa(
        this.form.value.nombre,
        this.form.value.nit,
        this.form.value.direccion,
        this.form.value.tipoFactura,
        this.form.value.tipoEmpresa,
        this.form.value.ciudad,
        this.form.value.image
      );
      
    this.form.reset();
  }
  

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}


