import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";


import { Sucursal } from "./sucursales.model";
import { Usuario } from "./usuario.model";



@Injectable({ providedIn: "root" })
export class GerenteService {
  
  private sucursales: Sucursal[] = [];
  private usuarios: Usuario[] = [];
  private elGrado: string;
  
  
  
  private sucursalesUpdated = new Subject<{ sucursales: Sucursal[] }>();
  private usuariosUpdated = new Subject<{ usuarios: Usuario[] }>();
  
  
  constructor(private http: HttpClient, private router: Router) {}
  
  /*
  addEmpresa(nombre: string | Blob, nit: string | Blob, direccion: string| Blob, tipofactura: string| Blob, tipoempresa: string| Blob, ciudad: string| Blob, image: File) {
      console.log('addEmpresa');
    const empresaData = new FormData();
    
    empresaData.append("nombre", nombre);
    empresaData.append("nit", nit);
    empresaData.append("direccion", direccion);
    empresaData.append("tipofactura", tipofactura);
    empresaData.append("tipoempresa", tipoempresa);
    empresaData.append("ciudad", ciudad);
    empresaData.append("image", image, "logo");
    this.http
      .post<{ nombre: String; nit: number; direccion: String; tipofactura: String; tipoempresa: String; foto: String }>(
        "http://68.183.168.180:3000/api/empresas/registro",
        empresaData
      )
      .subscribe(responseData => {
        this.router.navigate(["/empresas"]);
      });
  }
  */


getSucursales() {
    console.log("entramos al service")
    this.http
      .get<{ elError: boolean; sucursales: any  }>(
        "http://68.183.168.180:3000/api/gerente/sucursales"
      )
      .pipe(
        map(sucursalData => {
          return {
            sucursales: sucursalData.sucursales.map(sucursal => {
              return {
                id: sucursal.id,
                nombre: sucursal.nombre,
                direccion: sucursal.direccion,
                ciudad: sucursal.ciudad,
              };
            })
          };
        })
      )
      .subscribe(transformedSucursalData => {
        this.sucursales = transformedSucursalData.sucursales;
        this.sucursalesUpdated.next({
          sucursales: [...this.sucursales]
        });
      });
  }

  getSucursalUpdateListener() {
    return this.sucursalesUpdated.asObservable();
  }


  getUsuarios(sucursal: string) {
    
    this.http
      .get<{ elError: boolean; usuarios: any  }>(
        "http://68.183.168.180:3000/api/gerente/usuarios/" + sucursal
      )
      .pipe(
        map(usuarioData => {
          return {
            usuarios: usuarioData.usuarios.map(usuario => {
                
                console.log(usuario);
                if(usuario.id_permisos==1){
                    this.elGrado = "Administrador";
                }else if(usuario.id_permisos==2){
                    this.elGrado = "Gerente";
                }else if(usuario.id_permisos==3){
                    this.elGrado = "Almacen/Vendedor";
                }else if(usuario.id_permisos==4){
                    this.elGrado = "Vendedor";
                }else if(usuario.id_permisos==5){
                    this.elGrado = "Almacen";
                }
              return {
                id: usuario.id,
                nombre: usuario.nombre,
                apellido: usuario.apellido,
                email: usuario.email,
                celular: usuario.celular1,
                grado: this.elGrado
              };
            })
          };
        })
      )
      .subscribe(transformedUsuarioData => {
        this.usuarios = transformedUsuarioData.usuarios;
        this.usuariosUpdated.next({
          usuarios: [...this.usuarios]
        });
      });
  }

  getUsuariosUpdateListener() {
    return this.usuariosUpdated.asObservable();
  }

  addUsuario(nombre: string | Blob, apellido: string | Blob, email: string| Blob, password: string| Blob, sucursal: string| Blob, celular: string| Blob, permisos: string| Blob) {
  const usuarioData = new FormData();
  console.log(usuarioData);
  this.http
    .post<{ elError: string; mensaje: string}>(
      "http://68.183.168.180:3000/api/gerente/usuario/registro",
      {
        "nombre": nombre,
        "apellido": apellido,
        "email": email,
        "password": password,
        "celular": celular,
        "permisos": permisos,
        "sucursal": sucursal
        }
    )
    .subscribe(responseData => {
      this.router.navigate(["/gerente/lista_sucursales"]);
    });
}

addSucursal(nombre: string | Blob, direccion: string | Blob, ciudad: string| Blob) {
    const sucursalData = new FormData();    
    this.http
      .post<{ elError: string; mensaje: string}>(
        "http://68.183.168.180:3000/api/gerente/sucursal/registro",
        {
            "nombre": nombre,
            "direccion": direccion,
            "ciudad" : ciudad
        }
      )
      .subscribe(responseData => {
        this.router.navigate(["gerente/lista_sucursales"]);
      });
  }

  
}

